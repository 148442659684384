<template>
	<div class="padded">
		<line-loader :show="loading" />
		<p-dialog modal v-model:visible="show_campaign_change_modal" :style="{ width: '50vw' }">
			<template #header>
				<div class="flex align-items-center" style="gap: 10px">
					<icon type="alert" size="24px" style="color: var(--color-b)" />Changes for Campaign:
					<strong>{{ selected_campaign_change.campaign_name }}</strong>
				</div>
			</template>
			<code-editor :enabled="false" v-model.parse="selected_campaign_change.snapshot" />
		</p-dialog>
		<div v-if="campaign_activity.length === 0">
			<div class="sub-value">There are no campaign changes.</div>
		</div>
		<div id="note-campaign-changes-container" v-else>
			<div
				v-for="campaign_change in campaign_activity"
				@click="openCampaignChangeModal(campaign_change)"
				class="note"
				:class="{ clickable: !campaign_change.change_by }"
			>
				<div>
					<!-- we know this is a status change-->
					<template v-if="campaign_change.change_by">
						Campaign status for "<i>{{ campaign_change.campaign_name }}</i
						>" was set to <strong>{{ capitalize(campaign_change.status_change) }}</strong>
					</template>
					<template v-else>
						Campaign <strong>data</strong> change of "<i>{{ campaign_change.campaign_name }}</i
						>" created <strong>Version {{ campaign_change.version }}</strong
						>. Click to see raw data.
					</template>
					<gutter size="5px" />
					<div class="note-details">
						<span v-if="campaign_change.change_by"> Changed By {{ campaign_change.change_by }} - </span>
						<span v-else-if="campaign_change.modified_by_name">
							Changed By {{ campaign_change.modified_by_name }} -
						</span>
						{{ formatDate(new Date(campaign_change.timestamp), 'ddd, MMM D, YYYY hh:mm A') }}
					</div>
				</div>
				<gutter size="5px" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { capitalize, formatDate } from '@/lib/Filters';
import { reportCampaignActivity } from '@GQL';

export default {
	name: 'CampaignChangeHistory',
	components: {},
	data() {
		return {
			show_campaign_change_modal: false,
			loading: false,
			campaign_activity: [],
			selected_campaign_change: {},
		};
	},
	methods: {
		capitalize,
		formatDate,
		openCampaignChangeModal(cc) {
			if (cc.snapshot) {
				this.selected_campaign_change = cc;
				this.show_campaign_change_modal = true;
			}
		},
		campaignChangeHelper(cc) {
			// if the campaign change is from event log it will have a change_by field, and status_change
			if ('status_change' in cc) {
				return `Campaign status for '${cc.campaign_name}' set to ${capitalize(cc.status_change)}`;
			} else {
				return `Campaign data was changed for '${cc.campaign_name}'`;
				// its a generic change and we must have a modal to show the change object
			}
		},
		async getCampaignHistory() {
			const report_query = {
				account_id: this.accountId,
			};

			try {
				this.loading = true;
				const { rows } = await reportCampaignActivity(report_query);
				this.campaign_activity = rows;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get campaign versions',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
	async mounted() {
		await this.getCampaignHistory();
	},
	computed: {
		accountId() {
			return this.$route.params.account_id;
		},
	},
};
</script>

<style lang="less" scoped>
#note-campaign-changes-container {
	height: 580px;
	overflow-y: scroll;

	.note-details {
		color: var(--gray-35);
		font-size: 0.75rem;
		line-height: 1.25em;
	}
	.note {
		border-bottom: 1px dashed var(--gray-20);
		font-size: 0.875rem;
		line-height: 1.5em;
		margin-bottom: 10px;
		padding: 0 7px 7px 0;
	}
}
.line-loader {
	top: 68px;
}
</style>
