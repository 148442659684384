<template>
	<div class="shipper-form">
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Shipper Name:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="local_shipper.name" />
						<div v-if="v$.local_shipper.name.$error" class="validation-error">A shipper name is required</div>
					</div>
				</div>
			</div>
		</div>

		<div class="flex justify-content-start mb-2">
			<p-button label="Apply a Shipper Template" outlined @click="shipper_template_modal = true" />
		</div>

		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Shipper Type:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="local_shipper.type"
							:options="shipper_type_options"
							option-label="label"
							option-value="value"
							placeholder="Select Shipper Type"
						/>
						<div v-if="v$.local_shipper.type.$error" class="validation-error">A shipper type is required</div>
					</div>
				</div>
			</div>
		</div>

		<p-fieldset class="req" legend="Settings">
			<p-message v-if="!local_shipper.type" class="temp-message" :closable="false">
				Please select a shipper type
			</p-message>
			<component :is="shipperSettingsComponent[local_shipper.type]" v-model="local_shipper.settings" />
		</p-fieldset>

		<gutter size="1rem" />

		<div class="control-group">
			<div class="inner">
				<label class="control-label">Data Map:</label>
				<div class="sub-value mb-2">Map to the data to ship here. See <VariablesHelpersModal /></div>
				<div class="controls">
					<div class="field">
						<code-editor v-model="local_shipper.data_map" />
					</div>
				</div>
			</div>
		</div>

		<div class="cancellations padded">
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch input-id="cancel-enabled" v-model="local_shipper.cancellations.enabled" />
						</div>
						<gutter size="10px" />
						<div class="field caption">
							<label class="clickable" for="cancel-enabled">Allow this shipper to cancel purchases</label>
						</div>
					</div>
				</div>
			</div>
			<template v-if="local_shipper.cancellations.enabled">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Cancel Response Pattern:</label>
						<div class="controls">
							<div class="field">
								<p-input-text v-model="local_shipper.cancellations.pattern" />
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>

		<gutter size="1rem" />

		<div class="control-group">
			<div class="inner">
				<label class="control-label">Status:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="local_shipper.status"
							:options="status_options"
							option-label="label"
							option-value="value"
							placeholder="Set the Shipper Status"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="form-actions">
			<p-button label="Cancel" text @click="$router.back()" style="margin-right: auto" />
			<div class="flex justify-content-end gap-2">
				<TestShipperModal :shipper="local_shipper" :mpid="mpid" :account_id="accountId" />
				<p-button label="Save Shipper" @click="saveShipper">
					<template #icon>
						<div class="icon-wrapper button-icon">
							<icon type="check" size="24px" />
						</div>
					</template>
				</p-button>
			</div>
		</div>
		<p-dialog
			id="shipper-template-modal"
			style="width: 400px !important"
			v-model:visible="shipper_template_modal"
			:modal="true"
			:dismissable-mask="true"
			:pt="{
				root: { class: 'w-12 sm:w-9 md:w-6' },
			}"
		>
			<template #header>
				<div class="flex align-items-center w-full">
					<icon type="text-box-plus-outline" size="1.5rem" style="margin-right: 1rem" />
					<span class="font-bold">Apply a Shipper Template</span>
				</div>
			</template>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Select a Shipper Template:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								v-model="shipper_template"
								:options="shipper_template_options"
								option-label="label"
								option-value="value"
								placeholder="Select Shipper Template"
							/>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="flex justify-content-end">
					<p-button icon="pi pi-check" label="Apply Template" @click="applyTemplate" />
				</div>
			</template>
		</p-dialog>
	</div>
</template>

<script lang="ts">
import { isEqual, merge, find, cloneDeep } from 'lodash-es';
import customSettingsComponent from './SettingsComponents/custom.vue';
import emailSettingsComponent from './SettingsComponents/email.vue';
import httpSettingsComponent from './SettingsComponents/http.vue';
import zohoSettingsComponent from './SettingsComponents/zoho.vue';
import gohighlevelSettingsComponent from './SettingsComponents/gohighlevel.vue';
import pProgressSpinner from 'primevue/progressspinner';
import { getShipperTemplatesAsOptions, insertShipper, updateShipper } from '@GQL';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import TestShipperModal from './TestShipperModal.vue';
import VariablesHelpersModal from '../VariablesHelpersModal.vue';

export default {
	name: 'ShipperForm',
	components: {
		pProgressSpinner,
		TestShipperModal,
		VariablesHelpersModal,
	},
	props: {
		shipper: {
			type: Object,
			default() {
				return {
					id: null,
				};
			},
		},
	},
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			local_shipper: {
				name: null,
				cancellations: {
					enabled: false,
					pattern: '',
				},
				settings: {},
				status: 'active',
			},
			shipper_template: null,
			shipper_template_options: [],
			shipper_type_options: [
				{ label: 'HTTP', value: 'http' },
				// { label: 'Email', value: 'email' },
				{ label: 'Custom', value: 'custom' },
				{ label: 'Zoho', value: 'zoho' },
				{ label: 'GoHighLevel', value: 'gohighlevel' },
			],
			status_options: [
				{ label: 'Active', value: 'active' },
				{ label: 'Paused', value: 'paused' },
				{ label: 'Archived', value: 'archived' },
			],
			shipper_template_modal: false,
		};
	},
	computed: {
		shipperSettingsComponent() {
			return {
				custom: customSettingsComponent,
				email: emailSettingsComponent,
				http: httpSettingsComponent,
				zoho: zohoSettingsComponent,
				gohighlevel: gohighlevelSettingsComponent,
			};
		},
		accountId() {
			if ('account_id' in this.$route.params) {
				return this.$route.params.account_id;
			} else {
				return this.$root.sessionStore.user.account_id;
			}
		},
		mpid() {
			return this.$route.params.mpid || this.$root.appStore.mpid;
		},
	},
	watch: {
		shipper(new_value, old_value) {
			if (!isEqual(new_value, old_value)) {
				this.local_shipper = Object.assign(this.local_shipper, new_value);
			}
		},
	},
	async mounted() {
		try {
			this.shipper_template_options = await getShipperTemplatesAsOptions([[`status = 'active'`]]);
		} catch (err) {
			console.error(err, 'Unable to get Shipper Templates');
		}
	},
	methods: {
		applyTemplate() {
			const template = cloneDeep(find(this.shipper_template_options, { id: this.shipper_template }));
			if (template) {
				delete template.id;
				delete template.name;
				this.local_shipper = merge(this.local_shipper, template);
				this.shipper_template_modal = false;
				this.$toast.add({
					severity: 'success',
					summary: 'Applied Shipper Template',
					life: 2000,
				});
				this.shipper_template = null;
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Could not apply Shipper Template',
					life: 2000,
				});
			}
		},
		async saveShipper() {
			const is_valid = await this.v$.$validate();
			if (!is_valid) {
				setTimeout(() => {
					document.querySelector('.validation-error').closest('.control-group').scrollIntoView({ behavior: 'smooth' });
				}, 100);
			} else {
				try {
					let save_result;

					// Trim the shipper URL

					if (this.local_shipper.id) {
						save_result = await updateShipper(this.local_shipper.id, this.local_shipper);
					} else {
						// make sure the account_id is set
						this.local_shipper.account_id = this.accountId;
						save_result = await insertShipper(this.local_shipper);
					}

					this.$toast.add({
						severity: 'success',
						summary: 'The shipper was saved successfully',
						life: 3000,
					});
					this.$router.back();
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save the shipper',
						detail: err.message,
						life: 6000,
					});
				}
			}
		},
	},
	validations() {
		return {
			local_shipper: {
				name: { required },
				type: { required },
			},
		};
	},
};
</script>

<style scoped lang="less">
@import (reference) '@/styles/responsive';

.cancellations {
	border: 1px solid var(--gray-10);
	border-radius: 3px;
}

.form-actions {
	display: flex;
	justify-content: space-between;
	gap: 20px;
}

.temp-message {
	margin: 0;
}
.clickable:hover {
	cursor: pointer;
}
</style>
