<template>
	<div class="recent-auctions">
		<div class="flex align-items-center justify-content-between">
			<div class="">
				<div class="flex align-items-center">
					<p-input-switch v-model="hide_ping_auctions" /> <label>Hide Ping Auctions</label>
				</div>
			</div>
			<div>
				<p-button
					v-tooltip.top="'Refresh'"
					label="Refresh Results"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="refreshReports"
				/>
			</div>
		</div>
		<gutter size="20px" />
		<div class="qualified-auctions">
			<h3>Qualified Auctions</h3>
			<p-data-table :value="filteredQualifiedAuctions" :loading="loading">
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">This campaign has not qualified in any auctions for the date range selected.</div>
				</template>
				<p-column field="created_at" header="Auctioned On">
					<template #body="row">
						{{ formatDate(row.data.created_at, 'YYYY-MM-DD [at] h:mm:ssA') }}
						<div class="sub-value">{{ timeAgo(row.data.created_at) }}</div>
					</template>
				</p-column>
				<p-column v-if="$root.sessionStore.isAdminUser" field="auction_name" header="Auction">
					<template #body="row">
						<strong>
							<a
								class="auction-link"
								href="javascript:void(0)"
								v-tooltip.top="'View Auction Details'"
								@click="
									$refs.auction_modal.open(row.data.lead_id, {
										auction_log_id: row.data.auction_log_id,
									})
								"
							>
								{{ row.data.auction_name }}
							</a>
						</strong>
						<div class="sub-value">{{ row.data.auction_log_id }}</div>
					</template>
				</p-column>
				<p-column field="lead_id" header="Lead">
					<template #body="row">
						<template v-if="$root.sessionStore.isAdminUser || row.data.outcome === 'Won'">
							<strong
								><router-link :to="`/leads/${row.data.lead_id}`">{{ row.data.lead_id }}</router-link></strong
							>
							<div class="sub-value">{{ row.data.state }} &bull; {{ row.data.zip }}</div>
						</template>
						<template v-else>
							<strong>OUTBID</strong>
							<div class="sub-value">{{ row.data.state }} &bull; {{ row.data.zip }}</div>
						</template>
					</template>
				</p-column>
				<p-column field="bid" header="Bid">
					<template #body="row">
						{{ currency(row.data.bid) }}
					</template>
				</p-column>
				<p-column field="outcome" header="Outcome" class="column-align-center">
					<template #body="row">
						<div class="outcome">
							<template v-if="row.data.outcome === 'Won'"><icon class="green" type="check" size="18px" /></template>
							<template v-else><icon class="red" type="close" size="18px" /></template>
							{{ row.data.outcome }}
						</div>
					</template>
				</p-column>
				<p-column field="purchase_status" header="Purchase Status">
					<template #body="row">
						<template v-if="row.data.outcome === 'Won'">{{ title(row.data.purchase_status) }}</template>
					</template>
				</p-column>
			</p-data-table>
			<gutter size="10px" />
			<p-button
				v-tooltip.top="'Load More'"
				label="Load More"
				icon="pi pi-angle-double-down"
				aria-label="Load More"
				class="mr-2"
				@click="loadMoreAuctions"
			/>
		</div>
		<div class="disqualified-auctions">
			<h3>Disqualified Auctions</h3>
			<p-data-table :value="filteredDisqualifiedAuctions" :loading="loading">
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">This campaign has not been disqualified from any auctions for the date range selected.</div>
				</template>
				<p-column field="created_at" header="Auctioned On">
					<template #body="row">
						{{ formatDate(row.data.created_at, 'YYYY-MM-DD [at] h:mm:ssA') }}
						<div class="sub-value">{{ timeAgo(row.data.created_at) }}</div>
					</template>
				</p-column>
				<p-column v-if="$root.sessionStore.isAdminUser" field="auction_name" header="Auction">
					<template #body="row">
						<strong>
							<a
								class="auction-link"
								href="javascript:void(0)"
								v-tooltip.top="'View Auction Details'"
								@click="
									$refs.auction_modal.open(row.data.lead_id, {
										auction_log_id: row.data.auction_log_id,
									})
								"
							>
								{{ row.data.auction_name }}
							</a>
						</strong>
						<div class="sub-value">{{ row.data.auction_log_id }}</div>
					</template>
				</p-column>
				<p-column field="lead_id" header="Lead">
					<template #body="row">
						<template v-if="$root.sessionStore.isAdminUser">
							<strong
								><router-link :to="`/leads/${row.data.lead_id}`">{{ row.data.lead_id }}</router-link></strong
							>
							<div class="sub-value">{{ row.data.state }} &bull; {{ row.data.zip }}</div>
						</template>
						<template v-else>
							<strong>DISQUALIFIED</strong>
							<div class="sub-value">{{ row.data.state }} &bull; {{ row.data.zip }}</div>
						</template>
					</template>
				</p-column>
				<p-column field="dq_reason" header="Disqualification Reason">
					<template #body="row">
						{{ row.data.dq_reason }}
					</template>
				</p-column>
			</p-data-table>
			<gutter size="10px" />
			<p-button
				v-tooltip.top="'Load More'"
				label="Load More"
				icon="pi pi-angle-double-down"
				aria-label="Load More"
				class="mr-2"
				@click="loadMoreAuctions"
			/>
		</div>
		<auction-modal ref="auction_modal" />
	</div>
</template>

<script lang="ts">
import { getRecentAuctions } from '@GQL';
import AuctionModal from '@/views/Leads/panels/modals/AuctionModal.vue';
import pProgressSpinner from 'primevue/progressspinner';
import pTimeline from 'primevue/timeline';
import { currency, formatDate, timeAgo, title } from '@/lib/Filters';

const plReasons = ['Premium listings are not valid in this auction', 'Only premium listings are valid in this auction'];

const internalReasons = [
	'Sorted out of contention',
	'Premium listings are not valid in this auction',
	'Only premium listings are valid in this auction',
	'Promotion does not match current conditions',
	'Campaign promotion is not included in the auction configuration',
	'Promotion is not within start & end date',
	'Campaign promotion is no longer exists',
	'Campaign does not match source tags',
];

export default {
	name: 'CampaignStatsRecentAuctions',
	data() {
		return {
			hidden_reasons: this.$root.sessionStore.admin ? plReasons : internalReasons,
			qualified_auctions: [],
			disqualified_auctions: [],
			hide_ping_auctions: true,
			loading: false,
			offset: 0,
		};
	},
	components: {
		AuctionModal,
		pProgressSpinner,
		pTimeline,
	},
	props: {
		query: {
			type: Object,
			required: true,
		},
	},
	async mounted() {
		await this.getRecentAuctions();
	},
	computed: {
		filteredQualifiedAuctions() {
			if (this.hide_ping_auctions) {
				return this.qualified_auctions.filter((auction_log) => {
					return auction_log.lead_id !== 'D-PING-LEAD';
				});
			}
			return this.qualified_auctions;
		},
		filteredDisqualifiedAuctions() {
			if (this.hide_ping_auctions) {
				return this.disqualified_auctions.filter((auction_log) => {
					return auction_log.lead_id !== 'D-PING-LEAD';
				});
			}
			return this.disqualified_auctions;
		},
	},
	methods: {
		currency,
		formatDate,
		timeAgo,
		title,
		async getRecentAuctions(refresh = false) {
			try {
				this.loading = true;
				let no_cache = [];
				if (refresh) {
					no_cache = ['reportRecentAuctions'];
				}
				const recent_auctions = await getRecentAuctions(
					{
						campaign_id: this.$route.params.campaign_id,
						date_range: this.query.date_range,
						offset: this.offset,
					},
					no_cache
				);

				this.qualified_auctions = recent_auctions.qualified_auctions;
				this.disqualified_auctions = recent_auctions.disqualified_auctions;
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Failed to get recent auctions',
					detail: 'Try again or contact dev support',
				});
			} finally {
				this.loading = false;
			}
		},
		async loadMoreAuctions() {
			this.offset++;

			try {
				this.loading = true;
				const recent_auctions = await getRecentAuctions(
					{
						campaign_id: this.$route.params.campaign_id,
						date_range: this.query.date_range,
						offset: this.offset,
					},
					[]
				);

				this.qualified_auctions.push(...recent_auctions.qualified_auctions);
				this.disqualified_auctions.push(...recent_auctions.disqualified_auctions);
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Failed to get more auctions',
					detail: 'Try again or contact dev support',
				});
			} finally {
				this.loading = false;
			}
		},
		async refreshReports() {
			await this.getRecentAuctions(true).catch((err) => {});
		},
	},
};
</script>

<style lang="less" scoped>
h3 {
	font-size: 1.25em;
	font-weight: bold;
}

.qualified-auctions {
	margin-bottom: 2em;

	:deep(.p-message) {
		margin: 0 0;
	}

	:deep(.p-timeline-event-opposite) {
		flex: none;
		min-width: 130px;
	}

	.opposite {
		color: var(--gray-50);
		font-size: 0.875em;
		line-height: 1.35em;
	}

	.time-ago {
		color: var(--gray-35);
		font-size: 0.75em;
	}

	.outcome {
		align-items: flex-start;
		display: inline-flex;
		gap: 5px;

		.mdi-icon {
			color: var(--green);
		}

		.yellow {
			color: var(--yellow);
		}

		.red {
			color: var(--red);
		}
	}

	.trimmed {
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		text-wrap: nowrap;
		width: 100%;
	}
}
</style>
