<template>
	<div class="campaign-quick-filters">
		<quick-filter v-model="metric_type" value="dod">Day</quick-filter>
		<quick-filter v-model="metric_type" value="wow">Week</quick-filter>
		<quick-filter v-model="metric_type" value="mom">Month</quick-filter>
	</div>
	<gutter size="10px" />
	<p-data-table ref="dt" :loading="loading" :value="campaignsWithPurchases">
		<template #loading>
			<line-loader :show="loading" />
		</template>
		<template #empty>
			<div class="dim">No campaigns have purchased leads</div>
		</template>
		<p-column header="Campaign Name">
			<template #body="row">
				<div class="name">
					<router-link :to="campaignViewLink(row.data.id)">{{ row.data.name }}</router-link>
				</div>
				<div class="sub-value">{{ capitalize(row.data.product_targeting) }} - {{ capitalize(row.data.bid_type) }}</div>
			</template>
		</p-column>
		<p-column header="Status">
			<template #body="row">
				{{ capitalize(row.data.status) }}
			</template>
		</p-column>
		<p-column header="Vertical" class="column-align-center">
			<template #body="row">
				<vertical-icon :vertical-id="row.data.vertical_id_targeting" size="30px" />
			</template>
		</p-column>
		<p-column header="Purchases" class="column-align-right">
			<template #body="row">
				{{ row.data['purchases_' + campaignPeriod] || 0 }}
				<div
					:class="[
						'sub-value',
						trendClass(row.data['purchases_' + campaignPeriod], row.data['purchases_' + campaignPeriodCompare]),
					]"
				>
					<icon type="calendar-arrow-left" style="vertical-align: text-top; margin-top: 1px" />
					{{ row.data['purchases_' + campaignPeriodCompare] }}
				</div>
			</template>
		</p-column>
		<p-column header="Spend" class="column-align-right">
			<template #body="row">
				{{ currency(row.data['spend_' + campaignPeriod] || 0) }}
				<div
					:class="[
						'sub-value',
						trendClass(row.data['spend_' + campaignPeriod], row.data['spend_' + campaignPeriodCompare]),
					]"
				>
					<icon type="calendar-arrow-left" style="vertical-align: text-top; margin-top: 1px" />
					{{ currency(row.data['spend_' + campaignPeriodCompare]) }}
				</div>
			</template>
		</p-column>
		<p-column header="CPL" class="column-align-right">
			<template #body="row">
				{{ currency(row.data['spend_' + campaignPeriod] / row.data['purchases_' + campaignPeriod] || 0) }}
				<div
					:class="[
						'sub-value',
						trendClass(
							row.data['spend_' + campaignPeriod] / row.data['purchases_' + campaignPeriod] || 0,
							row.data['spend_' + campaignPeriodCompare] / row.data['purchases_' + campaignPeriodCompare] || 0
						),
					]"
				>
					<icon type="calendar-arrow-left" style="vertical-align: text-top; margin-top: 1px" />
					{{
						currency(row.data['spend_' + campaignPeriodCompare] / row.data['purchases_' + campaignPeriodCompare] || 0)
					}}
				</div>
			</template>
		</p-column>
	</p-data-table>
</template>

<script lang="ts">
import { capitalize, currency } from '@/lib/Filters';
import quickFilter from '@/components/widgets/QuickFilter.vue';
import trendClass from '@/lib/Utils/trendClass';
import { reportAccountActivity } from '@GQL';
import { cloneDeep, groupBy, mapValues, round } from 'lodash-es';

export default {
	name: 'CampaignActivity',
	components: {
		quickFilter,
	},
	data() {
		return {
			loading: false,
			metric_type: 'dod',
			campaigns: [],
		};
	},
	props: {
		campaigns: {
			type: Array,
			required: true,
		},
	},
	mounted() {
		this.getAccountVelocity();
	},
	methods: {
		capitalize,
		currency,
		trendClass,
		campaignViewLink(campaign_id) {
			return `/marketplaces/manage/${this.$route.params.mpid}/accounts/manage/${this.$route.params.account_id}/campaigns/${campaign_id}`;
		},
		async getAccountVelocity() {
			this.loading = true;
			try {
				const results = await reportAccountActivity({
					account_id: this.accountId,
				});

				this.report_data = results.reportAccountVelocity;
				this.campaigns = results.reportAccountVelocity.campaigns;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get account activity data',
					life: 3000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
	computed: {
		campaignPeriod() {
			if (this.metric_type === 'dod') {
				return 'today';
			}
			if (this.metric_type === 'wow') {
				return 'this_week';
			}
			return 'this_month';
		},
		campaignsWithPurchases() {
			return this.campaigns.filter((campaign) => {
				if (
					campaign['purchases_' + this.campaignPeriod] > 0 ||
					campaign['purchases_' + this.campaignPeriodCompare] > 0
				) {
					return true;
				}
				return false;
			});
		},
		campaignPeriodCompare() {
			if (this.metric_type === 'dod') {
				return 'yesterday';
			}
			if (this.metric_type === 'wow') {
				return 'last_week';
			}
			return 'last_month';
		},
		accountId() {
			return this.$route.params.account_id;
		},
		reportData() {
			const data = cloneDeep(this.report_data);
			delete data.campaigns;

			// Filter out unselected options
			const filtered_data = mapValues(data, (value, key) => {
				const filtered_values = value.filter((row) => {
					if (this.filters.vertical_id.length > 0 && !this.filters.vertical_id.includes(row.vertical_id)) {
						return false;
					}
					if (this.filters.product.length > 0 && !this.filters.product.includes(row.product)) {
						return false;
					}
					return true;
				});

				// Group by product
				let product_groups = groupBy(filtered_values, 'product');
				return mapValues(product_groups, (values, product) => {
					return values.reduce(
						(a, b) => {
							return {
								volume: a.volume + b.volume,
								spend: a.spend + b.spend,
							};
						},
						{
							volume: 0,
							spend: 0,
						}
					);
				});
			});

			// Add totals to each period
			const data_with_totals = mapValues(filtered_data, (products, period) => {
				const total = {
					volume: 0,
					spend: 0,
				};
				mapValues(products, (product_stats, product) => {
					total.volume += product_stats.volume;
					total.spend += product_stats.spend;

					product_stats.cpl = 0;
					if (product_stats.volume > 0) {
						product_stats.cpl = round(product_stats.spend / product_stats.volume, 2);
					}

					return product_stats;
				});

				total.cpl = 0;
				if (total.volume > 0) {
					total.cpl = round(total.spend / total.volume, 2);
				}

				products.total = total;
				return products;
			});

			return data_with_totals;
		},
	},
};
</script>

<style lang="less" scoped></style>
