<template>
	<p-dialog
		v-model:visible="show_modal"
		class="action-modal"
		:modal="true"
		:dismissable-mask="true"
		:pt="{
			root: { class: 'w-12 sm:w-12 md:w-6' },
		}"
	>
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<alt-icon type="sticky-note-2" size="24px" style="color: var(--color-b)" />
				<strong>Bulk Action</strong>
			</div>
		</template>
		<div class="flex-flex-column">
			<label>Accounts to Update:</label>

			<ol class="account-list">
				<li v-for="account in accounts" :key="account.id">
					<strong>{{ account.account_id }}</strong> - {{ account.account_name }}
				</li>
			</ol>

			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Disposition:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								v-model="disposition"
								name="disposition"
								:options="disposition_options"
								option-label="label"
								option-value="value"
							/>

							<div v-if="v$.disposition.$error" class="validation-error">
								{{ v$.disposition.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Note:</label>
					<div class="controls">
						<div class="field">
							<p-text-area v-model="note_body" />
							<div v-if="v$.note_body.$error" class="validation-error">
								{{ v$.note_body.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="email_template_options.length > 0" class="control-group">
				<div class="inner">
					<label class="control-label">Email Template:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								v-model="email_template"
								name="email_template"
								:options="email_template_options"
								option-label="label"
								option-value="value"
								@blur="loadTemplateContent"
							/>
						</div>
					</div>
				</div>
			</div>

			<gutter size="10px" />

			<p-select-button
				v-if="email_template"
				v-model="template_send_type"
				:options="template_send_options"
				option-label="label"
				option-value="value"
			/>

			<div v-if="email_template">
				<gutter size="20px" />
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Subject:</label>
						<div class="controls">
							<div class="field">
								<p-input-text v-model="custom_em_subject" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<p-message v-if="template_send_type === 'custom'" severity="warn" :closable="false">
				Do not update the tags like {{ '{' + '{user.first_name}' + '}' }}. These tags will be mapped/replaced with each
				respective buyer/account manager's data during the email send.
			</p-message>

			<div v-if="template_loading" class="template-loading">
				<p-ProgressSpinner />
			</div>

			<p-fieldset
				legend="Template Preview"
				v-if="
					email_template_options.length > 0 &&
					template_content &&
					template_send_type === 'template' &&
					!template_loading
				"
				class="template-preview"
			>
				<p><strong>Subject:</strong> {{ custom_em_subject }}</p>
				<div v-html="template_content.body"></div>
			</p-fieldset>

			<wysiwyg v-if="template_send_type === 'custom'" v-model="custom_em_body" />
		</div>

		<template #footer>
			<div class="flex justify-content-end">
				<p-button
					icon="pi pi-check"
					label="Apply Action to Selected Accounts"
					@click="submit"
					:loading="applying_action"
				/>
			</div>
		</template>
	</p-dialog>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import pProgressSpinner from 'primevue/progressspinner';
import pTextArea from 'primevue/textarea';
import { disposition_options } from '@/lib/Options';
import { getEmailTemplatesAsOptions, getEmailTemplateById, getSettings, amwBulkAction } from '@GQL';
import { clone } from 'lodash-es';

export default {
	name: 'BulkActionModal',
	props: {
		accounts: {
			type: Array,
			required: true,
		},
	},
	components: {
		pProgressSpinner,
		pTextArea,
	},
	emits: ['refresh'],
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			template_send_type: 'template',
			template_send_options: [
				{ label: 'Use Template As Is', value: 'template' },
				{ label: 'Customize Template', value: 'custom' },
			],
			show_modal: false,
			disposition: '',
			disposition_options,
			note_body: '',
			custom_em_subject: '',
			all_email_template_options: [],
			template_dispo_map: {},
			email_template_options: [],
			email_template: '',
			template_content: '',
			custom_em_body: '',
			template_loading: false,
			applying_action: false,
		};
	},
	async mounted() {
		const template_map = await getSettings(['amw_email_templates']);
		this.template_dispo_map = template_map.settings.amw_email_templates['existing_accounts'] || {};
		this.all_email_template_options = await getEmailTemplatesAsOptions([[`status = 'active'`]], 'amw');
	},
	methods: {
		async openModal() {
			// async load options
			this.reset();
			this.show_modal = true;
		},
		async loadTemplateContent() {
			if (this.email_template) {
				this.template_loading = true;
				try {
					this.template_content = (await getEmailTemplateById(this.email_template)).emailTemplate;
					this.custom_em_subject = clone(this.template_content.subject);
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Failed to Load Email Template',
						detail: err,
						life: 6000,
					});
				} finally {
					this.template_loading = false;
				}
			}
			if (this.template_send_type === 'custom') {
				this.custom_em_body = clone(this.template_content.body);
				this.custom_em_subject = clone(this.template_content.subject);
			}
		},
		async setTemplateOptions(disposition) {
			this.email_template_options = this.all_email_template_options.filter((row) => {
				return this.template_dispo_map[disposition];
			});
		},
		reset() {
			this.disposition = '';
			this.template_content = '';
			this.note_body = '';
			this.email_template = '';
			this.custom_em_subject = null;
			this.custom_em_body = null;
			this.template_send_type = 'template';
		},
		async submit() {
			try {
				this.applying_action = true;
				const is_valid = await this.v$.$validate();
				const account_ids = this.accounts.map((account) => account.account_id) as string[];

				if (is_valid) {
					const result = await amwBulkAction(
						account_ids,
						this.email_template,
						this.note_body,
						'amw_bulk_action',
						this.disposition,
						this.custom_em_subject,
						this.custom_em_body
					);

					if (result) {
						this.show_modal = false;
						this.$toast.add({
							severity: 'success',
							summary: 'Applied Action to Selected Accounts',
							detail: `${this.accounts.length} accounts were updated. ${result.emails_sent} emails sent.`,
							life: 3000,
						});
						this.$emit('refresh');
					} else {
						this.show_modal = false;
						this.$toast.add({
							severity: 'error',
							summary: 'Failed to Apply Action to Selected Accounts',
							life: 6000,
						});
					}
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Invalid Fields',
						life: 6000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Failed to Apply Action to Selected Accounts',
					detail: err,
					life: 6000,
				});
			} finally {
				this.applying_action = false;
			}
		},
	},
	watch: {
		disposition: {
			handler: function (new_value, old_value) {
				if (new_value && new_value !== '' && new_value !== old_value) {
					this.setTemplateOptions(new_value);
					if (this.email_template_options.length === 0) {
						this.email_template = '';
						this.template_content = '';
					}
				}
			},
			deep: true,
		},
		template_send_type: {
			handler: function (new_value) {
				if (new_value === 'template') {
					this.custom_em_body = null;
				} else {
					this.custom_em_body = clone(this.template_content.body);
					this.custom_em_subject = clone(this.template_content.subject);
				}
			},
			deep: true,
		},
	},
	validations() {
		return {
			disposition: {
				required: helpers.withMessage('Select a disposition', required),
			},
			note_body: {
				required: helpers.withMessage('Note is required', required),
			},
		};
	},
};
</script>

<style lang="less" scoped>
.account-list {
	padding-left: 1.5em;
	margin: 1rem 0;
	list-style-type: decimal;
}
.template-loading {
	margin: 1rem 0;
	text-align: center;
}
.template-preview {
	padding: 1rem;
	background: var(--gray-05);
	font-size: var(--font-size-sm);
}
</style>
