<template>
	<div id="form-marketplace">
		<loader :show="loading" />
		<p-card class="marketplace-form">
			<template #content>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="name">Name:</label>
						<div class="controls">
							<div class="field-row align-start gap-20">
								<div class="field">
									<p-input-text
										id="name"
										v-model="marketplace.name"
										class="p-inputtext"
										placeholder="Marketplace name"
									/>
									<div v-if="v$.marketplace.name.$error" class="validation-error">
										{{ v$.marketplace.name.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="id">MPID:</label>
						<div class="controls">
							<div class="field">
								<template v-if="existing_mp">
									<p-input-text disabled v-model="marketplace.id" />
								</template>
								<template v-else>
									<p-input-mask
										id="id"
										v-model="transformedMpid"
										:disabled="existing_mp"
										mask="aaa"
										:auto-clear="false"
										slot-char=""
										class="p-inputtext"
										placeholder="Must be a unique 3 character ID"
									/>
									<div v-if="v$.transformedMpid.$error" class="validation-error">
										{{ v$.transformedMpid.$errors[0].$message }}
									</div>
								</template>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="owner_id">Owner:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="marketplace.owner_id"
									option-label="label"
									option-value="value"
									:options="user_options"
									placeholder="Who manages this Marketplace for NextGen Leads?"
								/>
								<div v-if="v$.marketplace.owner_id.$error" class="validation-error">
									{{ v$.marketplace.owner_id.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<p-fieldset legend="Buyer Contact">
					<p>
						Please enter the details of the main contact on the buyer side. This is the person we will reach out to if
						there are any updates or concerns.
					</p>
					<div class="control-group">
						<div class="inner">
							<div class="controls gap-20">
								<div class="field">
									<label class="control-label">Full Name:</label>
									<p-input-text v-model="marketplace.contact.name" />
								</div>
								<div class="field">
									<label class="control-label">Title:</label>
									<p-input-text v-model="marketplace.contact.title" />
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<div class="controls gap-20">
								<div class="field">
									<label class="control-label">Email:</label>
									<p-input-text v-model="marketplace.contact.email" />
									<div v-if="v$.marketplace.contact.email.$error" class="validation-error">
										{{ v$.marketplace.contact.email.$errors[0].$message }}
									</div>
								</div>
								<div class="field">
									<label class="control-label">Phone:</label>
									<p-input-mask mask="(999) 999-9999" unmask slot-char=" " v-model="marketplace.contact.phone" />
									<div v-if="v$.marketplace.contact.phone.$error" class="validation-error">
										{{ v$.marketplace.contact.phone.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</p-fieldset>
				<gutter size="1em" />
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="status">Status:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="status"
									v-model="marketplace.status"
									option-label="label"
									option-value="value"
									:options="active_status_options"
									placeholder="Select status"
								/>
							</div>
						</div>
					</div>
				</div>
				<template v-if="$route.params.mpid">
					<div class="control-group">
						<div class="inner">
							<div class="controls gap-20">
								<div class="field">
									<label class="control-label">Logo:</label>
									<image-upload
										v-model="marketplace.settings.logo_url"
										filename="logo"
										folder="marketplace"
										mode="advanced"
									/>
								</div>
								<div class="field">
									<label class="control-label">Favicon:</label>
									<image-upload
										v-model="marketplace.settings.favicon_url"
										filename="favicon"
										folder="marketplace"
										mode="advanced"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Login Background:</label>
							<div class="controls">
								<div class="field">
									<image-upload
										v-model="marketplace.settings.login_bkg_url"
										filename="login_bkg"
										folder="marketplace"
										mode="advanced"
									/>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<p-message :closable="false">You can upload assets for this marketplace after it has been created</p-message>
				</template>

        <div class="control-group">
          <div class="inner">
            <label class="control-label">Default Parent Account ID:</label>
            <div class="controls">
              <div class="field">
                <p-input-text placeholder="e.g. NGL-000-000" v-model="marketplace.settings.default_parent_account_id"/>
                <p>When an account is created, if there is no specified parent_account_id, this field will designate the parent_account_id. E.g. Using GRF-000-000 so that all GRF accounts are children by default.</p>
              </div>
            </div>
          </div>
        </div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Theme Class:</label>
						<div class="controls">
							<div class="field">
								<p-input-text v-model="marketplace.settings.theme" />
							</div>
						</div>
					</div>
				</div>
			</template>
			<template #footer>
				<div class="flex justify-content-between">
					<p-button text label="Cancel" @click="$router.go(-1)" />
					<p-button icon="pi pi-check" label="Save Changes" @click="save" />
				</div>
			</template>
		</p-card>
	</div>
</template>
<script lang="ts">
import customUpload from '@/components/forms/CustomUpload.vue';
import { getInternalUsersAsOptions, getMarketplaceById, insertMarketplace, updateMarketplace } from '@GQL';
import { active_status_options } from '@/lib/Options';
import { email, helpers, required, minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { omit } from 'lodash-es';
import log from '@/lib/Log';
import imageUpload from '@/components/forms/ImageUpload.vue';
import pMessage from 'primevue/message';

const default_marketplace = {
	id: '',
	name: '',
	owner_id: '',
	settings: {
		logo_url: null,
		favicon_url: null,
		login_bkg_url: null,
		theme: 'default',
    default_parent_account_id: null
	},
	contact: {
		name: '',
		title: '',
		email: '',
		phone: '',
	},
	status: 'active',
};

export default {
	name: 'MarketplaceForm',
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	components: {
		customUpload,
		imageUpload,
		pMessage,
	},
	data() {
		return {
			active_status_options,
			existing_mp: false,
			loading: false,
			user_options: [],
			marketplace: { ...default_marketplace },
		};
	},
	computed: {
		transformedMpid: {
			get() {
				return this.marketplace.id;
			},
			set(new_value) {
				this.marketplace.id = new_value.toUpperCase();
			},
		},
	},
	validations() {
		return {
			transformedMpid: {
				required: helpers.withMessage('A 3-character MPID is required for this Marketplace', required),
				minLength: helpers.withMessage('The MPID must be 3 characters long', minLength(3)),
			},
			marketplace: {
				owner_id: {
					required: helpers.withMessage('Please select the NGL employee that manages this marketplace', required),
				},
				name: {
					required: helpers.withMessage('A name for this marketplace is required', required),
				},
				contact: {
					email: {
						email: helpers.withMessage('Please enter a valid email address', email),
					},
					phone: {
						minLength: helpers.withMessage('Please enter a valid phone number', minLength(10)),
					},
				},
			},
		};
	},
	async mounted() {
		this.loading = true;
		if ('mpid' in this.$route.params) {
			this.existing_mp = true;
			const mp = omit(await getMarketplaceById(this.$route.params.mpid), ['created_at', 'modified_at']);
			log.trace('MP LOADED', mp);
			this.marketplace = { ...default_marketplace, ...mp };
		}

		this.user_options = (await getInternalUsersAsOptions())[0].items;
		log.trace('USE', this.user_options);
		this.loading = false;
	},
	methods: {
		async save() {
			log.trace('Marketplace', this.marketplace);
			this.loading = true;
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				try {
					let result;

          if (this.marketplace.settings.default_parent_account_id === '') {
            // set to null
            this.marketplace.settings.default_parent_account_id = null;
          }

					if (this.existing_mp) {
						result = await updateMarketplace(this.marketplace.id, this.marketplace);
					} else {
						result = await insertMarketplace(this.marketplace);
					}
					log.trace('Res', result);

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: 'Marketplace was updated',
							life: 5000,
						});
						await this.$root.appStore.initialize();
						this.$router.back();
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save changes',
						life: 3000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'A field is invalid',
					life: 3000,
				});
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.marketplace-form {
	max-width: 760px;
}
</style>
