<template>
	<div id="form-user">
		<div class="control-group">
			<div class="inner">
				<div class="controls gap-20">
					<div class="field">
						<label class="control-label req" for="first_name">First Name:</label>
						<p-input-text id="first-name" v-model="local_user.first_name" class="p-inputtext" placeholder="First" />
						<div v-if="v$.local_user.first_name.$error" class="validation-error">
							{{ v$.local_user.first_name.$errors[0].$message }}
						</div>
					</div>
					<div class="field">
						<label class="control-label req" for="last_name">Last Name:</label>
						<p-input-text id="last-name" v-model="local_user.last_name" class="p-inputtext" placeholder="Last" />
						<div v-if="v$.local_user.last_name.$error" class="validation-error">
							{{ v$.local_user.last_name.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<div class="controls align-items-start gap-20">
					<div class="field">
						<label class="control-label req" for="email">Email:</label>
						<p-input-text
							id="email"
							v-model.trim="local_user.email"
							class="p-inputtext"
							placeholder="name@example.com"
						/>
						<div v-if="v$.local_user.email.$error" class="validation-error">
							{{ v$.local_user.email.$errors[0]?.$message }}
						</div>
					</div>
					<div class="field">
						<label class="control-label req" for="phone">Phone Number:</label>
						<p-input-mask
							id="phone"
							v-model="local_user.phone"
							mask="(999) 999-9999"
							:unmask="true"
							:auto-clear="false"
							placeholder="(999) 999-9999"
						/>
						<div v-if="v$.local_user.phone.$error" class="validation-error">
							{{ v$.local_user.phone.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label" for="title">Title:</label>
				<div class="controls">
					<div class="field">
						<p-input-text id="title" v-model="local_user.title" class="p-inputtext" placeholder="Insurance Agent" />
					</div>
				</div>
			</div>
		</div>

		<div class="control-group" v-if="this.$route.meta.user_type === 'admin'">
			<div class="inner">
				<label class="control-label" for="calendly">Calendly URL:</label>
				<div class="controls">
					<div class="field">
						<p-input-text
							id="calendly_url"
							v-model="local_user.settings.calendly_url"
							class="p-inputtext"
							placeholder="URL to calendly"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="control-group" v-if="this.$route.meta.user_type === 'admin'">
			<div class="inner">
				<label class="control-label" for="team">Team:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							id="team"
							v-model="local_user.settings.team"
							:options="team_options"
							option-label="label"
							option-value="value"
							placeholder="Internal Team (Sales, Marketing, Development)"
							@change="checkTeam"
						/>
					</div>
				</div>
			</div>
		</div>

		<p-fieldset :legend="!isCreate ? 'Change Password' : 'Set Password'">
			<p v-if="!isCreate" class="sub-value">Leave this blank to keep password unchanged.</p>
			<div v-if="!isCreate && $root.sessionStore.user.account_id" class="control-group">
				<div class="inner">
					<label class="control-label" for="current_password">Current Password:</label>
					<div class="controls gap-20">
						<div class="field">
							<p-password
								id="current_password"
								name="current_pasword"
								v-model="current_password"
								placeholder="Current Password"
								toggle-mask
								:feedback="false"
								autocomplete="new-password"
							/>
						</div>
						<div class="field"></div>
					</div>
				</div>
			</div>

			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field">
							<label class="control-label" :class="{ req: isCreate }" for="new_password">New Password:</label>
							<p-password
								id="new_password"
								v-model="new_password"
								placeholder="New Password"
								toggle-mask
								strong-regex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[!-\/:-@[-`{-~])(?=.*[0-9])(?=.{8,})"
							>
								<template #header>
									<div class="mb-2 font-bold">Pick a password</div>
								</template>
								<template #footer>
									<p-divider />
									<p class="mt-2">Password requirements</p>
									<ul class="pl-2 ml-2 mt-0" style="font-size: var(--font-size-sm); line-height: 1.5">
										<li>At least one lowercase</li>
										<li>At least one uppercase</li>
										<li>At least one numeric</li>
										<li>At least one special character</li>
										<li>Minimum 8 characters</li>
									</ul>
								</template>
							</p-password>
							<div v-if="v$.new_password.$error" class="validation-error">
								{{ v$.new_password.$errors[0].$message }}
							</div>
						</div>
						<div class="field">
							<label class="control-label" for="confirm_password" :class="{ req: isCreate }">
								Confirm New Password:
							</label>
							<p-password
								id="confirm_password"
								v-model="confirm_password"
								placeholder="Confirm New Password"
								:feedback="false"
								toggle-mask
							/>
							<div v-if="v$.confirm_password.$error" class="validation-error">
								{{ v$.confirm_password.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
				<div class="field" v-if="!isCreate">
					<gutter size="15px" />
					<p-button label="Send Password Reset email" @click="requestPasswordReset" />
				</div>
			</div>
		</p-fieldset>
		<template v-if="$route.meta.user_type === 'admin'">
			<p-fieldset
				legend="Admin Role"
				:pt="{
					legendTitle: { class: 'req' },
				}"
			>
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="adminRole"
									:options="adminRoleOptions"
									option-label="label"
									option-value="value"
									placeholder="Select a Role"
								/>
							</div>
						</div>
						<div v-if="v$.local_user.role_id.$error" class="validation-error">
							{{ v$.local_user.role_id.$errors[0].$message }}
						</div>
					</div>
				</div>
			</p-fieldset>
			<p-fieldset v-if="$route.meta.user_type === 'admin'" legend="Marketplace Access">
				<p-dialog header="Select Marketplace" v-model:visible="show_marketplace_modal" group="marketplace_select" modal>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Marketplace:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="marketplace_selection"
										:options="marketplaceOptions"
										option-label="label"
										option-value="value"
										placeholder="Select a Marketplace"
									/>
								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<p-button label="Cancel" text @click="show_marketplace_modal = false" />
						<p-button label="Add Marketplace" @click="saveMarketplaceRole" />
					</template>
				</p-dialog>
				<template v-for="marketplace_role in marketplace_roles">
					<div v-if="marketplace_role.mpid !== '$NG'" class="marketplace-role">
						<div class="control-group">
							<div class="inner">
								<div class="controls gap-10">
									<div class="field">
										<div class="marketplace-label">{{ marketplaceName(marketplace_role.mpid) }}</div>
									</div>
									<div class="field">
										<p-dropdown
											v-model="marketplace_role.role_id"
											:options="marketplaceRoleOptions(marketplace_role.mpid)"
											option-label="label"
											option-value="value"
											placeholder="Select Role"
										/>
									</div>
									<delete-action
										message="Are you sure you want to remove access to this marketplace?"
										@delete="deleteMarketplaceRole(marketplace_role.mpid)"
									/>
								</div>
							</div>
						</div>
					</div>
				</template>
				<div class="flex-row">
					<p-button icon="pi pi-plus" label="Add Marketplace" @click="addMarketplaceAccess()" />
				</div>
				<gutter size="15px" />
				<div v-if="v$.local_user.mp_roles.$error" class="validation-error">
					{{ v$.local_user.mp_roles.$errors[0].$message }}
				</div>
			</p-fieldset>
		</template>
		<template v-else>
			<template v-if="!$route.meta.profile">
				<p-fieldset
					legend="Access Role"
					:pt="{
						legendTitle: { class: 'req' },
					}"
				>
					<div class="control-group">
						<div class="inner">
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="userRole"
										:options="userRoleOptions"
										option-label="label"
										option-value="value"
										placeholder="Select a Role"
									/>
								</div>
							</div>
							<div v-if="v$.local_user.role_id.$error" class="validation-error">
								{{ v$.local_user.role_id.$errors[0].$message }}
							</div>
						</div>
					</div>
				</p-fieldset>
			</template>
		</template>
		<!-- <p-fieldset legend="2-Factor Authentification">
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field">
							<label class="control-label" for="mfa_enabled">Enable 2-Factor Authentification (2FA)?</label>
						</div>
						<div class="field fit">
							<p-input-switch id="mfa_enabled" v-model="local_user.mfa_enabled" />
						</div>
					</div>
					<div v-if="v$.local_user.mfa_enabled.$error" class="validation-error">
						{{ v$.local_user.mfa_enabled.$errors[0].$message }}
					</div>
				</div>
			</div>
			<template v-if="local_user.mfa_enabled">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">2FA Delivery Type:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="local_user.mfa_delivery"
									:options="mfa_options"
									placeholder="Select Type"
									option-label="label"
									option-value="value"
								/>
								<div v-if="v$.local_user.mfa_delivery.$error" class="validation-error">
									{{ v$.local_user.mfa_delivery.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</p-fieldset> -->
		<template v-if="!$route.meta.profile">
			<gutter size="20px" />
			<div class="control-group">
				<div class="inner">
					<label class="control-label req" for="status">Status:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								id="status"
								v-model="local_user.status"
								:options="status_options"
								placeholder="Select Status"
								option-label="label"
								option-value="value"
							/>
							<div v-if="v$.local_user.status.$error" class="validation-error">
								{{ v$.local_user.status.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<div v-if="showActions" class="actions">
			<div class="flex justify-content-between">
				<p-button text label="Cancel" @click="$router.go(-1)" />
				<p-button
					icon="pi pi-check"
					:loading="loading"
					:label="$route.meta.new ? 'Create User' : 'Save Changes'"
					@click.stop="save"
				></p-button>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { $GQL, getResetToken, checkExistingUserEmail, initUserForm, insertUser, updateUser } from '@GQL';
import { cloneDeep, forIn, includes, remove, find, every, isEmpty } from 'lodash-es';
import { mapStores } from 'pinia';
import { PropType } from 'vue';
import { required, email, requiredIf, sameAs, helpers } from '@vuelidate/validators';
import { useMarketplaceStore } from '@/stores/marketplace';
import { useVuelidate } from '@vuelidate/core';
import customUpload from '@/components/forms/CustomUpload.vue';
import debounceAsync from 'debounce-async';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import log from '@/lib/Log';
import pDialog from 'primevue/dialog';
import pDivider from 'primevue/divider';
import type { User } from '@nextgenleads/auth-driver';
import { passwordStrength } from '@/lib/Utils/passwordStrength';
import Loader from '@/App.vue';
import { z } from 'zod';

export default {
	name: 'EditUser',
	components: {
		Loader,
		pDivider,
		pDialog,
		deleteAction,
		customUpload,
	},
	inject: {
		mq: {
			from: 'mq',
		},
	},
	props: {
		title: {
			type: String,
		},
		showActions: {
			type: Boolean,
			default: true,
		},
		user: {
			type: Object as PropType<Partial<User>>,
			default() {
				return {};
			},
		},
	},
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		const status_options = [
			{ label: 'Active', value: 'active' },
			{ label: 'Suspended', value: 'suspended' },
			{ label: 'Archived', value: 'archived' },
		];

		const mfa_options = [
			{ label: 'Email', value: 'email' },
			{ label: 'SMS', value: 'sms' },
		];

		const team_options = [
			{ label: 'Marketing', value: 'marketing' },
			{ label: 'Sales', value: 'sales' },
			{ label: 'Development', value: 'development' },
			{ label: 'Call Center', value: 'call_center' },
		];

		return {
			loading: false,
			loaded_roles: false,
			status_options,
			mfa_options,
			team_options,
			new_password: '',
			current_password: null,
			confirm_password: '',
			show_add_role: false,
			selected_mpid: '',
			selected_role: null,
			mpid_list: [],
			role_list: [],
			edit_key: '',
			edited_key: '',
			edited_role: '',
			roles: [],
			marketplace_roles: [],
			show_marketplace_modal: false,
			marketplace_selection: null,
			local_user: {
				first_name: '',
				last_name: '',
				email: '',
				phone: '',
				avatar_url: '',
				title: '',
				role_id: {},
				account_id: null,
				mfa_enabled: false,
				mfa_delivery: null,
				status: 'active',
				settings: {},
			},
		};
	},
	watch: {
		// If this is a new admin user, add all marketplaces by default, only do once
		marketplaceOptions(newValue) {
			if (newValue && !isEmpty(newValue) && this.loaded_roles === false) {
				if (this.$route.meta.user_type === 'admin' && this.$route.meta.new) {
					this.addAllMarketplaceOptions();
					this.loaded_roles = true;
				}
			}
		},
	},
	computed: {
		accountId() {
			return (
				this.$route.params.account_id || (this.$root.sessionStore.account ? this.$root.sessionStore.account.id : null)
			);
		},
		isCreate() {
			return this.$route.meta.new;
		},
		adminRole: {
			get() {
				if (this.$route.meta.user_type === 'admin') {
					const admin_role = find(this.marketplace_roles, { mpid: '$NG' });
					if (admin_role) {
						return admin_role.role_id;
					}
				}
				return null;
			},
			set(new_value) {
				const admin_role = find(this.marketplace_roles, { mpid: '$NG' });
				if (admin_role) {
					admin_role.role_id = new_value;
				}
				if (new_value === 'live_transfer_agent') {
					this.deleteNonAdminRoles();
				}
				// this.local_user.role_id['$NG'] = new_value;
			},
		},
		adminRoleOptions() {
			return this.roles.filter((role) => {
				return role.type === 'admin';
			});
		},
		userRole: {
			get() {
				return this.local_user.role_id[this.mpid];
			},
			set(new_value) {
				this.local_user.role_id[this.mpid] = new_value;
			},
		},
		isParentAccountAdmin() {
			return this.$root.sessionStore.user.role_id[this.mpid] === 'parent_account_admin';
		},
		userRoleOptions() {
			return this.roles.filter((role) => {
				if (this.isParentAccountAdmin) {
					return role.type === this.$route.meta.user_type && role.value !== 'parent_account_admin';
				} else {
					return role.type === this.$route.meta.user_type;
				}
			});
		},
		marketplaceOptions() {
			const current_mpids = this.marketplace_roles.map((role) => role.mpid);
			return this.mpid_list.filter((mpid) => {
				return !includes(current_mpids, mpid.value);
			});
		},
		uniqueEmail() {
			return debounceAsync(async function (email) {
				const email_exists = await checkExistingUserEmail(email, this.local_user.id);
				return !email_exists;
			}, 500);
		},
		validEmail() {
			return debounceAsync(async function (email) {
				const parsed_email = z.string().email().safeParse(email);
				if (parsed_email.success) {
					return true;
				}
				return false;
			}, 500);
		},
		mpid() {
			return this.$route.params.mpid || this.marketplaceStore.mpid || this.$root.appStore.mpid;
		},
		...mapStores(useMarketplaceStore),
	},
	validations() {
		return {
			new_password: {
				requiredIf: helpers.withMessage(
					'New password required.',
					requiredIf(this.current_password || this.$route.meta.new)
				),
				passwordStrength: helpers.withMessage('Password is not secure enough.', passwordStrength),
			},
			confirm_password: {
				requiredIf: helpers.withMessage('Please confirm the password.', requiredIf(this.new_password)),
				sameAsPassword: helpers.withMessage('Passwords must match.', sameAs(this.new_password)),
			},
			local_user: {
				first_name: {
					required: helpers.withMessage('User First Name required.', required),
					restricted_chars: helpers.withMessage(
						'Some special characters are not allowed for first name.',
						helpers.regex(/^[^*|\\"\[\]<>{}=#;@]*$/)
					),
				},
				last_name: {
					required: helpers.withMessage('User Last Name required.', required),
					restricted_chars: helpers.withMessage(
						'Some special characters are not allowed for last name.',
						helpers.regex(/^[^*|\\"\[\]<>{}=#;@]*$/)
					),
				},
				role_id: {
					// required: helpers.withMessage('User Role is required', required),
					admin_required: helpers.withMessage('User Role is required.', (value) => {
						const admin_role = find(this.marketplace_roles, { mpid: '$NG' });
						return this.$route.meta.user_type === 'admin' ? admin_role && !isEmpty(admin_role.role_id) : true;
					}),
					mp_only_required: helpers.withMessage('User Role is required.', (value) => {
						return this.$route.meta.user_type !== 'admin' ? !isEmpty(this.local_user.role_id[this.mpid]) : true;
					}),
				},
				mp_roles: {
					mp_admin_required: helpers.withMessage('At least one Marketplace Role is required', (value) => {
						const filtered = this.marketplace_roles.filter((role) => {
							return role.mpid !== '$NG';
						});
						const non_null = every(filtered, (role) => {
							return role.role_id !== null;
						});

						return this.$route.meta.user_type === 'admin'
							? (filtered.length > 0 && non_null) || this.adminRole === 'live_transfer_agent'
							: true;
					}),
				},
				email: {
					required: helpers.withMessage('User Email is required.', required),
					email,
					validEmail: helpers.withMessage('Must be a valid email.', helpers.withAsync(this.validEmail)),
					uniqueEmail: helpers.withMessage(
						'A user with that email address already exists.',
						helpers.withAsync(this.uniqueEmail)
					),
				},
				phone: {
					required: helpers.withMessage('User Phone is required.', required),
				},
				mfa_enabled: {
					required: helpers.withMessage('MFA option must have a value', required),
				},
				mfa_delivery: {
					requiredIf: helpers.withMessage('MFA delivery method required if enabled', requiredIf(this.mfa_enabled)),
				},
				status: {
					required: helpers.withMessage('Status is required.', required),
				},
			},
		};
	},
	methods: {
		async requestPasswordReset() {
			// get MPID from the account id or just use (admin) for non ngl users
			let sub_domain = 'admin';
			if ('account_id' in this.local_user && this.local_user.account_id && this.local_user.account_id !== '') {
				sub_domain = this.local_user.account_id.slice(0, 3).toLowerCase();
			}
			try {
				await getResetToken(this.local_user.email, `https://${sub_domain}.nextgenleads.app`);
				this.$toast.add({
					severity: 'success',
					summary: 'Password Reset Requested',
					detail: `User should be receiving an email shortly with instructions to update their password`,
					life: 5000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Password Reset Error',
					detail: 'A user with the specified email does not exist',
					life: 3000,
				});
			}
		},
		marketplaceName(mpid) {
			const mp = this.mpid_list.find((item) => {
				return item.value === mpid;
			});

			if (mp) {
				return `${mp.label} (${mpid})`;
			}

			return 'Unknown';
		},
		async getMarketplaceIds() {
			// only to be used for admins in the non-mpid manage mode
			const query = `query GetMarketplaceIds {
				marketplaces {
					label: name
					value: id
				}
			}`;
			try {
				const mp_result = await $GQL.request(query);
				return mp_result.marketplaces;
			} catch (err) {
				log.error('Unable to get Marketplaces');
				return [];
			}
		},
		addMarketplaceAccess() {
			this.marketplace_selection = null;
			this.show_marketplace_modal = true;
		},
		saveMarketplaceRole() {
			this.marketplace_roles.push({
				mpid: this.marketplace_selection,
				role_id: null,
			});
			this.marketplace_selection = null;
			this.show_marketplace_modal = false;
		},
		deleteMarketplaceRole(mpid) {
			remove(this.marketplace_roles, (marketplace_role) => {
				return marketplace_role.mpid === mpid;
			});
		},
		deleteNonAdminRoles() {
			remove(this.marketplace_roles, (role) => {
				return role.mpid !== '$NG';
			});
		},
		marketplaceRoleOptions(mpid) {
			const filtered_roles = this.roles.filter((role) => {
				return role.type === 'marketplace' && (role.mpid === mpid || role.mpid === '$NG');
			});

			return filtered_roles.map((role) => {
				return {
					label: role.label,
					value: role.value,
				};
			});
		},
		addAllMarketplaceOptions() {
			this.marketplaceOptions.forEach((mp) => {
				this.marketplace_roles.push({
					mpid: mp.value,
					role_id: null,
				});
			});
		},
		checkTeam() {
			// Call center users do not need access to marketplaces
			if (this.local_user.settings.team === 'call_center') {
				this.deleteNonAdminRoles();
			}
		},
		async handleInsertUser() {
			this.loading = true;
			// Map roles back into the user
			if (this.$route.meta.user_type == 'admin') {
				let role_id = {};

				this.local_user.account_id = null;

				this.marketplace_roles.forEach((role) => {
					role_id[role.mpid] = role.role_id;
				});

				this.local_user.role_id = role_id;
			}

			let data = { password: this.new_password, ...cloneDeep(this.local_user) };

			try {
				const result = await insertUser(data);
				this.$toast.add({
					severity: 'success',
					summary: 'User Inserted',
					detail: 'Successfully inserted user.',
					life: 3000,
				});

				return result;
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Error updating user',
					detail: 'See logs for more details',
					life: 5000,
				});
				return null;
			} finally {
				this.loading = false;
			}
		},
		async handleUpdateUser(user_id) {
			let data;
			this.loading = true;

			// Map roles back into the user
			if (this.$route.meta.user_type == 'admin') {
				let role_id = {};
				this.marketplace_roles.forEach((role) => {
					role_id[role.mpid] = role.role_id;
				});

				this.local_user.role_id = role_id;
			}

			// Take out id, created_at for update
			let updated_user = cloneDeep(this.local_user);

			// Remove the last login value
			delete updated_user.last_login;

			// Check password update, only own user or admin roles can change someone's password from UI
			if (this.current_password || (this.$root.sessionStore.user.account_id === null && this.new_password !== '')) {
				data = {
					...updated_user,
					password_change: {
						current_password: this.current_password,
						new_password: this.new_password,
					},
				};
			} else {
				data = {
					...updated_user,
				};
			}

			try {
				const result = await updateUser(user_id, data);
				this.$toast.add({
					severity: 'success',
					summary: 'User Updated',
					detail: 'Successfully updated user.',
					life: 3000,
				});
				return result;
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Error updating user',
					detail: e.response.errors[0].message,
					life: 5000,
				});
				return null;
			} finally {
				this.loading = false;
			}
		},
		async save($event, stay = false) {
			let user;

			const is_valid = await this.v$.$validate();
			if (is_valid) {
				// Check route for create should work for new users
				if (this.$route.meta.new) {
					user = await this.handleInsertUser();
				} else {
					user = await this.handleUpdateUser(this.$route.params.user_id || this.$root.sessionStore.user.id);
				}

				// If this is the current user, refresh the app
				if (user.id === this.$root.sessionStore.user.id) {
					await this.$root.sessionStore.initialize();
				}

				if (stay) {
					// Return the user object
					return user;
				} else {
					this.$router.back();
				}
				return null;
			} else {
				document.querySelector('.validation-error').closest('.control-group').scrollIntoView({ behavior: 'smooth' });
			}
		},
		async user_fields() {
			// Return only fields for account creation
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				return { ...this.local_user, password: this.new_password };
			}
		},
	},
	async beforeMount() {
		let roles_filters = [];
		switch (this.$route.meta.user_type) {
			case 'admin':
				// We want all the roles
				break;
			case 'marketplace':
				// We want global and MPID-specific roles for marketplace users
				roles_filters.push([`(mpid = '${this.mpid}' OR mpid = '$NG') AND type = 'marketplace'`]);
				break;
			case 'account':
				// We want global and MPID-specific roles for marketplace users
				roles_filters.push([`(mpid = '${this.mpid}' OR mpid = '$NG') AND type = 'account'`]);
				break;
		}

		// If we are passing a partial user prop, set those fields in the form
		if (this.user.settings === null) {
			this.local_user = { ...this.local_user, ...this.user, settings: { calendly_url: '', team: '' } };
		} else {
			this.local_user = { ...this.local_user, ...this.user };
		}

		const init_result = await initUserForm({
			user_id: this.$route.params.user_id || 'new',
			roles_filters,
		});

		if (init_result.user) {
			if (init_result.user.settings === null) {
				this.local_user = {
					...init_result.user,
					settings: {
						calendly_url: '',
						team: '',
					},
				};
			} else {
				this.local_user = init_result.user;
			}

			this.marketplace_roles = [];
			forIn(this.local_user.role_id, (role_id, mpid) => {
				this.marketplace_roles.push({
					mpid,
					role_id,
				});
			});
		} else {
			this.marketplace_roles = [];
			if (this.$route.meta.user_type === 'admin') {
				this.marketplace_roles.push({
					mpid: '$NG',
					role_id: null,
				});
			} else {
				this.marketplace_roles.push({
					mpid: this.$route.params.mpid || this.$root.appStore.mpid,
					role_id: null,
				});
			}
		}
		this.roles = init_result.roles;
		if (this.$route.meta.user_type === 'admin') {
			this.mpid_list = await this.getMarketplaceIds();
		}
	},
	mounted() {
		if (!this.$route.params.user_id) {
			if (this.$route.params.account_id) {
				this.local_user.account_id = this.$route.params.account_id;
			} else if (this.$route.params.parent_account_id) {
				this.local_user.account_id = this.$route.params.parent_account_id;
			} else if (this.$root.sessionStore.isAccountUser && this.$root.sessionStore.account.id) {
				this.local_user.account_id = this.$root.sessionStore.account.id;
			}
		}
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

label {
	padding-left: 0.25rem;
}

.marketplace-label {
	align-items: center;
	background-color: var(--gray-05);
	border: 1px solid var(--gray-15);
	border-radius: 3px;
	color: var(--gray-50);
	display: flex;
	height: 40px;
	padding: 0 0.75em;
}

.marketplace-role {
	margin-bottom: 10px;
}

.change-password {
	border: 1px solid var(--gray-15);
	border-radius: 2px;
	line-height: 1.5;
	margin-top: 20px;
	padding: 1rem;
	position: relative;

	.title {
		background: white;
		font-size: 1.25rem;
		font-weight: bold;
		left: 0.5rem;
		padding: 0.5rem;
		position: absolute;
		top: -1.125rem;
	}

	.subtitle {
		color: var(--gray-50);
		padding-top: 0.5rem;
	}

	.mobile ({
		padding: 0;
		border: 0;

		.subtitle {
			padding: 0;
		}

		.title {
			position: relative;
			padding: 0;
			left: unset;
			top: unset;
		}
	});;
}

.toggle-options {
	font-size: 1rem;
	line-height: 1.5;
	max-width: 700px;

	.option {
		align-items: center;
		display: flex;
		justify-content: space-between;
		text-align: left;

		.question {
			padding-left: 0.5rem;
		}

		.mobile ({
			.question {
				padding: 0 0 0.5rem;
			}

			padding: 0;
			flex-direction: column;
			align-items: flex-start;
		});;
	}

	.mobile({
		padding: 0;
	});
}

.role-section {
	border: 1px solid var(--gray-15);
	border-radius: 4px;
	color: var(--black);
	padding: 1rem;
	width: auto;

	.role {
		color: var(--black);
		font-size: var(--font-size-base);
		font-weight: bold;
	}

	.marketplace {
		color: var(--gray-50);
		font-size: var(--font-size-sm);
	}
}

.actions {
	margin-top: 2em;
}
</style>
