import { $GQL } from '@GQL/index';

export async function testPromotion(params: { lead: unknown; filter: unknown; timezone: string; date: string }) {
	const query = `
        query TestPromotion($params: JSONObject!) {
            testPromotion(params: $params)
        }
    `;

	const results = await $GQL.request(query, { params });
	return results.testPromotion;
}
