<template>
	<div>
		<p-button label="Test Shipper with Data Object" @click="openModal">
			<template #icon>
				<div class="icon-wrapper button-icon">
					<icon type="cube-send" size="24px" />
				</div>
			</template>
		</p-button>
		<p-dialog
			id="test-shipper-modal"
			:style="{ width: '50vw' }"
			v-model:visible="test_shipper_modal"
			:modal="true"
			@after-hide="reset"
		>
			<loader :show="response_loading || loading_test_data" />
			<template #header>
				<div class="flex align-items-center w-full">
					<icon type="cube-send" size="1.5rem" style="margin-right: 1rem" />
					<span class="font-bold">Test Shipper with Data Object</span>
				</div>
			</template>

			<div v-if="step === 1">
				<p>
					In order to emulate production, you must select one of the Account's existing campaigns. This selection also
					determines what lead is pulled into the test.
				</p>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req">Campaign:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="campaign_id"
									:options="campaign_options"
									option-label="label"
									option-value="value"
									placeholder="Select a Campaign"
								/>

								<div v-if="campaign_id_missing" class="validation-error">A campaign must be selected</div>
							</div>
						</div>
					</div>
				</div>

				<div v-if="campaign_id !== ''" class="control-group">
					<gutter size="20px" />
					<div class="inner">
						<p>
							If you want to specify a real lead_id, enter it here. The lead override below will still change some of
							the values
						</p>
						<label class="control-label">Lead ID Overrider:</label>
						<div class="controls">
							<div class="field">
								<p-input-text v-model="real_lead_id" placeholder="Optional real lead ID" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="step === 2">
				<p>
					The data below is current data pulled from our database, and has had the PII information over-written. If you
					want to make additional changes you can do them below. Ideally you should preview the mapping before sending
					the test.
				</p>

				<div v-if="mapped_data" class="control-group">
					<div class="inner">
						<label class="control-label">Data Map Test:</label>
						<div class="controls">
							<div class="field">
								<code-editor id="mapped-json" v-model.parse="mapped_data" />
							</div>
						</div>
					</div>
				</div>

				<p-accordion id="test_data" v-model:activeIndex="active_section">
					<p-accordion-tab>
						<template #header>
							<icon type="account" size="24px" class="campaign-icon" />
							<span>Test Lead</span>
						</template>
						<p>
							If you want to send different zip, state, names, emails or phones, you'd make changes in this section.
						</p>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Lead Object:</label>
								<div class="controls">
									<div class="field">
										<code-editor id="lead-json" v-model.parse="test_lead" />
									</div>
								</div>
							</div>
						</div>
					</p-accordion-tab>
					<p-accordion-tab>
						<template #header>
							<icon type="shopping" size="24px" class="campaign-icon" />
							<span>Test Purchase</span>
						</template>
						<p>
							Note that this isn't the complete purchase object. We show the most common purchase fields sent in shipper
							maps. If you want to over-write any other just add them below accordingly.
						</p>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Purchase Object Partial:</label>
								<div class="controls">
									<div class="field">
										<code-editor id="lead-json" v-model.parse="local_purchase_override" />
									</div>
								</div>
							</div>
						</div>
					</p-accordion-tab>
					<p-accordion-tab>
						<template #header>
							<icon type="map-marker-circle" size="24px" class="campaign-icon" />
							<span>Campaign: {{ test_campaign.name }}</span>
						</template>
						<p>
							Note that this isn't the complete campaign object. We show the most common campaign fields sent in shipper
							maps. If you want to over-write any other just add them below accordingly.
						</p>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Campaign Object:</label>
								<div class="controls">
									<div class="field">
										<code-editor id="test-json" v-model.parse="local_campaign_override" />
									</div>
								</div>
							</div>
						</div>
					</p-accordion-tab>
				</p-accordion>
			</div>
			<div v-if="step === 3">
				<p-accordion id="result" v-model:activeIndex="active_test_sections" multiple>
					<p-accordion-tab v-if="test_response && test_response.return_result">
						<template #header>
							<span>Shipper Result: {{ test_response.return_result.status }}</span>
						</template>

						<div class="control-group">
							<div class="inner">
								<label class="control-label">Processed Result:</label>
								<div class="controls">
									<div class="field">
										<code-editor id="test-json" v-model.parse="test_response.return_result" />
									</div>
								</div>
							</div>
						</div>
					</p-accordion-tab>

					<p-accordion-tab>
						<template #header>
							<span>Raw Response</span>
						</template>

						<row class="justify-content-end">
							<p-button v-tooltip.top="'Copy Raw Response'" aria-label="Copy URL" @click="copyData(rawResponse)">
								<template #icon>
									<icon type="clipboard-plus-outline" size="20px" />
								</template>
							</p-button>
						</row>

						<div class="control-group">
							<div class="inner">
								<label class="control-label">Raw Response:</label>
								<div class="controls">
									<div class="field">
										<code-editor id="raw-req-json" v-model.parse="rawResponse" />
									</div>
								</div>
							</div>
						</div>
					</p-accordion-tab>
					<p-accordion-tab>
						<template #header>
							<span>Raw Request</span>
						</template>

						<row class="justify-content-end">
							<p-button v-tooltip.top="'Copy Raw Request'" aria-label="Copy URL" @click="copyData(rawRequest)">
								<template #icon>
									<icon type="clipboard-plus-outline" size="20px" />
								</template>
							</p-button>
						</row>

						<div class="control-group">
							<div class="inner">
								<label class="control-label">Raw Request:</label>
								<div class="controls">
									<div class="field">
										<code-editor id="raw-req-json" v-model.parse="rawRequest" />
									</div>
								</div>
							</div>
						</div>
					</p-accordion-tab>
					<p-accordion-tab>
						<template #header>
							<span>Shipment Log</span>
						</template>

						<div class="control-group">
							<div class="inner">
								<label class="control-label">Test Shipment Log:</label>
								<div class="controls">
									<div class="field">
										<code-editor id="test-shipment-log-json" v-model.parse="test_response.shipment_log" />
									</div>
								</div>
							</div>
						</div>
					</p-accordion-tab>
				</p-accordion>
			</div>
			<template #footer>
				<div class="w-full flex justify-content-between align-items-center">
					<p-button label="Cancel" text @click="test_shipper_modal = !test_shipper_modal" />
					<div class="actions">
						<p-button v-if="!test_response && step === 2" label="Preview Mapping" @click="mapData">
							<template #icon>
								<div class="icon-wrapper button-icon">
									<icon type="map" size="24px" />
								</div>
							</template>
						</p-button>
						<p-button
							v-if="test_response"
							label="Try different override"
							@click="
								test_response = null;
								step = 2;
								mapped_data = '';
								active_test_sections = [0, 1];
							"
						/>

						<p-button v-if="step === 1" label="Get Test Data" @click="getTestData" :disabled="campaign_id === ''">
							<template #icon>
								<div class="icon-wrapper button-icon">
									<icon type="target" size="24px" />
								</div>
							</template>
						</p-button>

						<p-button v-if="step == 2" label="Send Test" @click="testShipper">
							<template #icon>
								<div class="icon-wrapper button-icon">
									<icon type="cube-send" size="24px" />
								</div>
							</template>
						</p-button>
					</div>
				</div>
			</template>
		</p-dialog>
	</div>
</template>
<script lang="ts">
import copyToClipboard from 'copy-to-clipboard';
import { mapper } from '@/lib/Utils/mapper';
import { getCampaignsAsOptions, shipByData, getShipperTestData } from '@GQL';
import { ulid } from 'ulid';
import { get, cloneDeep, merge, pick } from 'lodash-es';

import pAccordion from 'primevue/accordion';
import pAccordionTab from 'primevue/accordiontab';

export default {
	components: {
		pAccordion,
		pAccordionTab,
	},
	name: 'TestShipperModal',
	props: {
		account_id: {
			type: String,
			required: true,
		},
		mpid: {
			type: String,
			required: true,
		},
		shipper: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			active_section: null,
			active_test_sections: [0, 1],
			step: 1,
			campaign_id_missing: false,
			test_shipper_modal: false,
			response_loading: false,
			show_campaign: false,
			show_lead: false,
			show_purchase: false,
			local_campaign_override: {},
			local_purchase_override: {
				id: ulid(),
			},
			// Generate random test phone/email
			local_lead_override: {
				phone: Math.floor(3234567891 + Math.random() * 1234567891).toString(),
				email: `testlead+${Math.floor(Math.random() * 1000)}@nextgenleads.com`,
				first_name: 'TEST',
				last_name: 'LEAD',
			},
			test_purchase: null,
			test_lead: null,
			test_response: null,
			test_campaign: null,
			real_lead_id: '',
			campaign_id: '',
			campaign_options: [],
			mapped_data: '',
			loading_test_data: false,
		};
	},
	computed: {
		rawResponse() {
			if (this.test_response) {
				return get(this.test_response, 'shipment_log.response', {});
			} else {
				return {};
			}
		},
		rawRequest() {
			return get(this.test_response, 'shipment_log.request', {});
		},
	},
	async mounted() {
		this.campaign_options = await getCampaignsAsOptions([
			[`account_id = '${this.account_id}' AND status != 'archived'`],
		]);
	},
	methods: {
		get,
		copyData(data) {
			if (typeof data !== 'string') {
				data = JSON.stringify(data, null, 4);
			}

			copyToClipboard(data);
			this.$toast.add({
				severity: 'success',
				summary: 'Copied to Clipboard',
				life: 3000,
			});
		},
		async getTestData() {
			this.loading_test_data = true;
			try {
				const params = {
					campaign_id: this.campaign_id,
				};
				if (this.real_lead_id !== '') {
					params.lead_id = this.real_lead_id;
				}
				const { campaign, purchase, lead } = await getShipperTestData(params);

				this.test_lead = merge({}, lead, this.local_lead_override);

				this.test_campaign = merge({}, campaign);
				this.test_purchase = merge({}, purchase, { id: ulid() });

				// DIFFER THIS TO WHEN WE MADE THE REQUEST
				// pull the data that might need to change
				this.local_campaign_override = pick(this.test_campaign, ['name', 'id', 'created_at']);
				this.local_purchase_override = pick(this.test_purchase, ['price', 'id', 'created_at']);

				this.step = 2;
			} finally {
				this.loading_test_data = false;
			}
		},
		async mapData() {
			if (this.shipper.data_map) {
				this.response_loading = true;
				try {
					let mapped = '';
					if (this.shipper.data_map) {
						this.test_campaign = merge({}, this.test_campaign, this.local_campaign_override);
						this.test_purchase = merge({}, this.test_purchase, this.local_purchase_override);
						mapped = mapper.compile(this.shipper.data_map, {
							lead: this.test_lead,
							purchase: this.test_purchase,
							campaign: this.test_campaign,
						});
					}
					this.active_section = null;
					this.mapped_data = JSON.parse(mapped);
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Error mapping data',
						detail: err.response.errors[0]?.message || err.message,
						life: 5000,
					});
				} finally {
					this.response_loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Error mapping data',
					detail: 'Nothing to map',
					life: 5000,
				});
			}
		},
		async testShipper() {
			this.campaign_id_missing = this.campaign_id === '';
			if (this.campaign_id_missing) {
				this.$toast.add({
					severity: 'error',
					summary: 'A required field has not been set',
					life: 5000,
				});
			} else {
				this.response_loading = true;
				try {
					const test_shipper = { ...cloneDeep(this.shipper) };
					if (!('id' in test_shipper) || !('account_id' in test_shipper)) {
						test_shipper.id = ulid();
						test_shipper.account_id = this.account_id;
					}

					// MAKE SURE THE LATEST IS MERGED IN
					this.test_campaign = merge({}, this.test_campaign, this.local_campaign_override);
					this.test_purchase = merge({}, this.test_purchase, this.local_purchase_override);

					this.test_response = await shipByData({
						campaign: this.test_campaign,
						lead: this.test_lead,
						purchase: this.test_purchase,
						shipper: test_shipper,
					});
					this.step = 3;
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to test the shipper',
						detail: err.message,
					});
				} finally {
					this.response_loading = false;
				}
			}
		},
		reset() {
			this.test_campaign = null;
			this.test_lead = null;
			this.test_purchase = null;
			this.real_lead_id = '';
			this.mapped_data = '';
			this.active_test_sections = [0, 1];
			this.active_section = null;
			this.step = 1;
			this.test_response = null;
			this.campaign_id = '';
		},
		openModal() {
			this.reset();
			// reset
			this.test_shipper_modal = true;
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

.campaign-icon {
	margin: 0 0.5rem;
}
</style>
