<template>
	<div>
		<p-button label="Transfer Funds" @click="openModal">
			<template #icon>
				<div class="icon-wrapper button-icon">
					<icon type="bank-transfer" size="24px" />
				</div>
			</template>
		</p-button>

		<p-dialog v-model:visible="show_modal" :modal="true" :dismissble-mask="true" style="width: 480px">
			<loader :show="loading" />
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="cash" size="24px" style="color: var(--color-b)" />
					<strong>Sending Transfer From: {{ accountId }}</strong>
				</div>
			</template>

			<div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Recipient Account:</label>
						<div class="controls">
							<div class="field">
								<p-input-text v-model="recipient_account_id" :placeholder="accountHolderText" />
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Transfer Amount:</label>
						<div class="controls">
							<div class="field">
								<p-input-currency v-model="transfer_amount" :invalid="v$.transfer_amount.$error" />
								<div v-if="v$.transfer_amount.$error" class="validation-error">
									{{ v$.transfer_amount.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="control-group">
					<div class="inner">
						<label class="control-label">Memo:</label>
						<div class="controls">
							<div class="field">
								<p-text-area v-model="memo" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<template #footer>
				<div class="flex justify-content-end">
					<p-button label="Transfer Funds" @click="transferFunds" />
				</div>
			</template>
		</p-dialog>

		<p-confirm-dialog />
	</div>
</template>

<script>
import pConfirmDialog from 'primevue/confirmdialog';
import pDialog from 'primevue/dialog';
import { maxValue, helpers, minValue } from '@vuelidate/validators';
import { transferFunds, getAccountsForTransfers, getBalances, getTransactionsList } from '@GQL';
import { useVuelidate } from '@vuelidate/core';
import { currency } from '@/lib/Filters';
import { get } from 'lodash-es';
import pTextArea from 'primevue/textarea';
export default {
	name: 'SimpleTransferFundsModal',
	components: {
		pDialog,
		pTextArea,
		pConfirmDialog,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			show_modal: false,
			transfer_amount: 0,
			loading: false,
			recipient_account_id: '',
			sender_balance: 0,
			memo: '',
		};
	},
	async mounted() {
		const res = await getBalances(this.accountId);
		this.sender_balance = res.actual;
	},
	computed: {
		accountId() {
			return (
				this.$route.params.account_id || this.$route.params.parent_account_id || this.$root.sessionStore.account.id
			);
		},
		mpid() {
			return this.$route.params.mpid || this.$root.sessionStore.account.mpid;
		},
		accountHolderText() {
			// get mpid and then return string
			return `Account ID e.g. ${this.accountId.slice(0, 3)}-000-000`;
		},
	},
	methods: {
		currency,
		// method to match with debounce
		openModal() {
			this.memo = '';
			this.recipient_account_id = '';
			this.transfer_amount = 0;
			this.show_modal = true;
		},
		async transferFunds() {
			// check if the sender is an account
			const recipient = await getAccountsForTransfers([[`id = '${this.recipient_account_id}'`]]);

			const is_valid = await this.v$.$validate();

      if (!is_valid) {
				this.$toast.add({
					severity: 'error',
					summary: `The balance of ${this.accountId} is not enough for this transfer`,
					life: 6000,
				});
				return;
			} else if (recipient.length !== 1 || !/[A-Z]{3}-[0-9]{3}-[0-9]{3}/.test(this.recipient_account_id)) {
				this.$toast.add({
					severity: 'error',
					summary: `The account id '${this.recipient_account_id}' was not found.`,
					life: 10000,
				});
				return;
			} else {
				// lets do confirmation
				const message = `
        Transfer ${this.currency(this.transfer_amount)} from ${this.accountId} to ${this.recipient_account_id} - ${get(recipient, '0.name')}
        ${get(recipient, '0.primary_user.first_name')}  ${get(recipient, '0.primary_user.last_name')}
        `;
				this.$confirm.require({
					header: 'Confirm Transfer',
					message,
					accept: async () => {
						this.loading = true;
						try {
							const transfer = {
								sender_account_id: this.accountId,
								receiver_account_id: this.recipient_account_id,
								amount: this.transfer_amount,
								user_id: this.$root.sessionStore.user.id,
							};
							const result = await transferFunds([transfer], this.memo);

							this.$toast.add({
								severity: 'success',
								summary: 'Transfer were completed',
								life: 10000,
							});
							this.show_modal = false;
							this.recipient_account_id = '';
							this.transfer_amount = 0;
						} catch (err) {
							this.$toast.add({
								severity: 'error',
								summary: `An error occurred during the transfer`,
								life: 6000,
							});
						} finally {
							this.loading = false;
						}
					},
					reject: () => {
						this.$toast.add({
							severity: 'info',
							summary: 'Cancel',
							detail: 'Transaction will not occur',
							life: 3000,
						});
					},
				});
			}
		},
	},
	validations() {
		return {
			transfer_amount: {
				required: helpers.withMessage('Transfer amount must be greater than $0', minValue(0.01)),
				not_enough_balance: helpers.withMessage(
					'Account does not have enough balance to transfer this amount',
					maxValue(this.sender_balance)
				),
			},
		};
	},
};
</script>
