import { $GQL } from '@GQL/index';

export async function reportAccountActivity(params: { account_id: string }) {
	const query = `
        query ReportAccountVelocity($params: JSONObject!) {
            reportAccountVelocity(params: $params)
            reportCampaignActivity(params: $params) {
				rows
				row_count
			}
        }
    `;

	const results = await $GQL.request(query, {
		params,
	});
	return results;
}
