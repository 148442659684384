<template>
	<iframe v-if="report_url" ref="grafana-dash" id="dashboard-embed" :src="report_url" frameborder="0"></iframe>
</template>

<script lang="ts">
export default {
	name: 'DashboardAMPhoneActivity',
	data() {
		return {
			report_url: null,
		};
	},
	mounted() {
		this.report_url =
			'https://grafana.prod.nextgenleads.dev/d/ae8x6plc68npcf/sales-dash?orgId=1&refresh=5s&from=1736176221001&to=1736197821001&theme=dark';
	},
	beforeUnmount() {},
	methods: {},
};
</script>

<style lang="less" scoped>
#dashboard-embed {
	height: 100%;
}
</style>
