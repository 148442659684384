<template>
	<p-card>
		<template #title>
			<div class="flex align-items-center">
				<icon type="link-variant" size="24px" class="mr-2" />
				<span class="name">Source</span>
			</div>
		</template>
		<template #content>
			<h3 class="heading">Source Information</h3>
			<ul class="table-list">
				<template v-if="$root.sessionStore.isAdminUser">
					<li>
						<div class="label">Vendor</div>
						<div class="value">{{ lead.vendor_id || 'N/A' }}</div>
					</li>
					<li>
						<div class="label">Vendor Lead ID</div>
						<div v-if="lead.vendor_lead_id" class="value">
							{{ lead.vendor_lead_id }}
						</div>
						<div v-else class="value">None Specified</div>
					</li>
					<li v-if="lead.product === 'call'">
						<div class="label">Data Lead ID</div>
						<div v-if="lead.meta.data_lead_id" class="value">
							<router-link :to="`/leads/${lead.meta.data_lead_id}`">
								{{ lead.meta.data_lead_id }}
							</router-link>
						</div>
						<div v-else class="value">None Specified</div>
					</li>
					<li>
						<div class="label">Source Type</div>
						<div class="value">
							{{ lead.source_type || 'N/A' }}
						</div>
					</li>
				</template>
				<li>
					<div class="label">Source ID</div>
					<div v-if="$root.sessionStore.isAdminUser" class="value">
						{{ lead.source_id || 'N/A' }}
						<div v-if="lead.source_segments?.data_source_id" class="sub-value">
							{{ sourceHash(lead.source_id) || 'N/A' }}
						</div>
						<div v-else class="sub-value">{{ lead.source_hash || 'N/A' }}</div>
					</div>
					<div v-else class="value">
						{{ lead.source_hash || 'N/A' }}
					</div>
				</li>
				<li v-if="$root.sessionStore.isAdminUser && lead.source_segments?.data_source_id">
					<div class="label">Data Source ID</div>
					<div class="value">
						{{ lead.source_segments?.data_source_id || 'N/A' }}
						<div class="sub-value">{{ sourceHash(lead.source_segments.data_source_id) || 'N/A' }}</div>
					</div>
				</li>
				<li>
					<div class="label">Sub ID</div>
					<div v-if="$root.sessionStore.isAdminUser" class="value">
						{{ lead.sub_id || 'N/A' }}
						<div class="sub-value">{{ lead.sub_id_hash || 'N/A' }}</div>
					</div>
					<div v-else class="value">
						{{ lead.sub_id_hash || 'N/A' }}
					</div>
				</li>
				<template v-if="$root.sessionStore.isAdminUser">
					<template v-for="(value, key) in lead.source_segments" :key="key">
						<li v-if="!['source_id', 'sub_id', 'data_source_id'].includes(key)">
							<div class="label">{{ segments[key] || capitalize(key) }}</div>
							<div class="value">
								{{ value || 'null' }}
								<div v-if="key === 'campaign_id'" class="sub-value">{{ sourceHash(value) }}</div>
							</div>
						</li>
					</template>
				</template>
			</ul>
			<h3 class="heading">Tokens</h3>
			<ul class="table-list">
				<li>
					<div class="label">LEADiD Token</div>
					<div class="value">
						{{ lead.leadid_token || 'N/A' }}
					</div>
				</li>
				<li v-if="lead.trustedform_id">
					<div class="label">TrustedForm</div>
					<div v-if="$root.sessionStore.isAdminUser" class="value">
						<a :href="lead.trustedform_share_url" target="_blank">{{ lead.trustedform_id }}</a
						><br />
						<div class="sub-value">{{ lead.trustedform_cert_url }}</div>
					</div>
					<div v-else class="value">
						{{ lead.trustedform_id }}
					</div>
				</li>
			</ul>
		</template>
	</p-card>
</template>

<script lang="ts">
import pCard from 'primevue/card';
import { capitalize, sourceHash } from '@/lib/Filters';

export default {
	name: 'SourcePanel',
	components: {
		pCard,
	},
	props: {
		lead: {
			type: Object,
		},
	},
	data() {
		return {
			segments: {
				ad_group: 'Ad Group',
				publisher: 'Publisher',
				channel: 'Channel',
				campaign_id: 'Campaign ID',
				campaign_name: ' Campaign Name',
				medium: 'Medium',
				keywords: 'Keywords',
				url: 'URL',
				sub_id: 'Sub ID',
				site_id: 'Site ID',
				s1: 'S1',
				s2: 'S2',
				s3: 'S3',
				s4: 'S4',
			},
		};
	},
	methods: {
		capitalize,
		sourceHash,
	},
};
</script>
