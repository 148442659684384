<template>
	<div id="promotion-form">
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="name">Name:</label>
				<div class="controls">
					<div class="field">
						<p-input-text id="name" v-model="promotion.name" placeholder="A name for this promotion" />
						<div v-if="v$.promotion.name.$error" class="validation-error">
							{{ v$.promotion.name.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label" for="image">Image:</label>
				<div class="sub-value mb-2">Image to use for Promotion Select in Campaign Edit (optional)</div>
				<div class="controls">
					<div class="field">
						<image-upload
							id="image"
							v-model="promotion.icon_url"
							:filename="`promotion-bg-${promotion.name.replace(/\s+/g, '')}`.trim()"
							folder="promotions"
							mode="advanced"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Description:</label>
				<div class="controls">
					<div class="field">
						<p-text-area
							id="description"
							v-model="promotion.description"
							class="w-full"
							placeholder="Add a description about this promotion for campaign edit"
						/>
						<div v-if="v$.promotion.description.$error" class="validation-error">
							{{ v$.promotion.description.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Restrictions:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="promotion.restrictions" />
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="vertical">Vertical:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							id="vertical"
							v-model="promotion.vertical_id"
							:options="vertical_options"
							option-label="label"
							option-value="value"
						/>
						<div v-if="v$.promotion.vertical_id.$error" class="validation-error">
							{{ v$.promotion.vertical_id.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="product">Product:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							id="product"
							v-model="promotion.product"
							:options="product_options"
							option-label="label"
							option-value="value"
						/>
						<div v-if="v$.promotion.product.$error" class="validation-error">
							{{ v$.promotion.product.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="bid-type">Bid Types:</label>
				<div class="controls">
					<div class="field">
						<p-multi-select
							v-model="promotion.bid_types"
							:options="bid_type_options"
							option-label="label"
							option-value="value"
							placeholder="Select Bid Types"
							class="w-full"
						/>
						<div v-if="v$.promotion.bid_types.$error" class="validation-error">
							{{ v$.promotion.bid_types.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label visually-hidden" for="start-date">Start Date:</label>
				<div class="controls">
					<div class="field">
						<date-picker
							id="start-date"
							v-model="promotion.start_date"
							hide-label
							show-time
							:showButtonBar="true"
							label="Promotion Start Date (optional)"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label visually-hidden" for="start-date">End Date:</label>
				<div class="controls">
					<div class="field">
						<date-picker
							id="end-date"
							v-model="promotion.end_date"
							show-time
							:showButtonBar="true"
							label="Promotion End Date (optional)"
							:default-time="{ hours: '23', minutes: '59', seconds: '59' }"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Matching Criteria (optional):</label>
				<div class="sub-value mb-2">
					Custom filters to add conditions for the promotion to work (Time restrictions, specific days, etc.)
					<a @click.prevent="show_guide_modal = true"
						>Guide <icon type="open-in-new" style="display: inline-table"
					/></a>
				</div>
				<div class="controls">
					<div class="field">
						<code-editor v-model.parse.lazy="promotion.custom_filter" />
					</div>
				</div>
			</div>
		</div>
		<div class="flex justify-content-end">
			<p-button label="Test Matching Criteria" @click="handleTestModal" />
		</div>
		<p-fieldset legend="Campaign Restrictions">
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch input-id="allow_source_filtering" v-model="promotion.allow_source_filtering" />
						</div>
						<div class="field caption">
							<label for="allow_source_filtering" class="control-label">Allow Source Filtering</label>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch input-id="allow_attribute_filtering" v-model="promotion.allow_attribute_filtering" />
						</div>
						<div class="field caption">
							<label for="allow_attribute_filtering" class="control-label">Allow Attribute Filtering</label>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch input-id="allow_schedule" v-model="promotion.allow_schedule" />
						</div>
						<div class="field caption">
							<label for="allow_schedule" class="control-label">Allow Campaign Schedule</label>
						</div>
					</div>
				</div>
			</div>
		</p-fieldset>
		<gutter size="20px" />
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="exclusive-bid">Exclusive Minimum Bid:</label>
				<div class="controls">
					<div class="field">
						<p-input-currency id="exclusive-bid" v-model="promotion.exclusive_minimum_bid" />
					</div>
				</div>
				<div v-if="v$.promotion.exclusive_minimum_bid.$error" class="validation-error">
					{{ v$.promotion.exclusive_minimum_bid.$errors[0].$message }}
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="shared-bid">Shared Minimum Bid:</label>
				<div class="controls">
					<div class="field">
						<p-input-currency id="shared-bid" v-model="promotion.shared_minimum_bid" />
					</div>
					<div v-if="v$.promotion.shared_minimum_bid.$error" class="validation-error">
						{{ v$.promotion.shared_minimum_bid.$errors[0].$message }}
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Status:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="promotion.status"
							:options="active_status_options"
							option-label="label"
							option-value="value"
							placeholder="Select Status"
						/>
					</div>
					<div v-if="v$.promotion.status.$error" class="validation-error">
						{{ v$.promotion.status.$errors[0].$message }}
					</div>
				</div>
			</div>
		</div>
	</div>
	<gutter size="20px" />
	<div class="footer flex justify-content-end">
		<p-button :label="$route.meta.new ? 'Create Promotion' : 'Update Promotion'" @click="handleSave" />
	</div>
	<p-dialog
		v-model:visible="show_test_modal"
		:modal="true"
		@after-hide="test_results = null"
		:pt="{
			root: { class: 'w-12 sm:w-12 md:w-6' },
		}"
	>
		<template #header>
			<div class="flex align-items-center justify-start gap-1">
				<icon type="test-tube" size="20px" />
				<strong>Test Matching Criteria</strong>
			</div>
		</template>
		<div class="control-group">
			<div class="inner">Test a date/time to see if it will match your custom filter criteria.</div>
			<gutter size="20px" />
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Matching Criteria (optional):</label>
					<div class="sub-value mb-2">
						Custom filters to add conditions for the promotion to work (Time restrictions, specific days, etc.)
					</div>
					<div class="controls">
						<div class="field">
							<code-editor v-model.parse.lazy="promotion.custom_filter" />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label visually-hidden">Date:</label>
					<div class="controls">
						<div class="field">
							<date-picker
								v-model="test_date"
								hide-label
								show-time
								date-format="D yy-mm-dd"
								:showButtonBar="true"
								label="Date to test:"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Timezone:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								id="timezone"
								v-model="timezone"
								:options="timezoneOptions"
								option-label="label"
								option-value="value"
								placeholder="Select Timezone"
							/>
						</div>
					</div>
				</div>
			</div>
			<div v-if="test_loading && !test_results" class="text-center">
				<p-ProgressSpinner />
			</div>
			<div v-if="!test_loading && test_results">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Result:</label>
						<div class="controls">
							<div class="field" style="color: var(--color-b)">
								{{ test_results }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Test Filter" @click="testFilter" :loading="test_loading" />
			</div>
		</template>
	</p-dialog>
	<p-dialog
		v-model:visible="show_guide_modal"
		:modal="true"
		:pt="{
			root: { class: 'w-12 sm:w-12 md:w-6' },
		}"
	>
		<template #header>
			<div class="flex align-items-center justify-start gap-1">
				<icon type="map-legend" size="20px" />
				<strong>Matching Criteria Guide</strong>
			</div>
		</template>
		<p>Add custom filters to promotions to apply the promotion when it meets your conditions.</p>
		<gutter size="20" />
		<h2>Filter By Day</h2>
		<p>The week starts on Sunday so when filtering by day 1 is Sunday:</p>
		<p>
			<code style="color: var(--orange-600)">1 - Sunday</code>
			<code style="color: var(--orange-600)">2 - Monday</code>
			<code style="color: var(--orange-600)">3 - Tuesday</code>
			<code style="color: var(--orange-600)">4 - Wednesday</code>
			<code style="color: var(--orange-600)">5 - Thursday</code>
			<code style="color: var(--orange-600)">6 - Friday</code>
			<code style="color: var(--orange-600)">7 - Saturday</code>
		</p>
		<p>So to filter for weekends only an example filter would be:</p>
		<highlightjs language="javascript" :code="weekend_filter" style="white-space: pre-line" />
		<gutter size="20px" />
		<h2>Filter By Hour</h2>
		<p>Similarly you can filter by the hour of the day, this would be for off hours:</p>
		<highlightjs language="javascript" :code="late_night_filter" style="white-space: pre-line" />
		<gutter size="20px" />
		<h2>Filter by Both</h2>
		<p>
			You can combine both by making 2 sets of rules, one for days and one for certain days at different hours, this
			would be for weekends, Friday, and Thursday after 12pm:
		</p>
		<highlightjs language="javascript" :code="both_filter" style="white-space: pre-line" />
	</p-dialog>
</template>

<script lang="ts">
import 'highlight.js/styles/stackoverflow-light.css';
import '@/lib/Utils/isDst';
import { useSessionStore } from '@/stores/session';
import imageUpload from '@/components/forms/ImageUpload.vue';
import { required, helpers, minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { vertical_options, active_status_options } from '@/lib/Options';
import pTextArea from 'primevue/textarea';
import datePicker from '@/components/forms/DatePicker.vue';
import { cloneDeep, get, sortBy } from 'lodash-es';
import TIMES from '@/lib/Data/times.json';
import TIMEZONES from '@/lib/Data/timezones.json';
import { insertPromotion, updatePromotion, getPromotionById, testPromotion } from '@GQL';
import pProgressSpinner from 'primevue/progressspinner';

const test_lead = {
	id: 'T-0000-0000',
	mpid: '$NG',
	ulid: '01JH33PN4TFXQ9B0XZXJ2ASDFG',
	vendor_id: 'nextgenleads',
	vendor_lead_id: 'T-0000-0000',
	product: 'data',
	vertical_id: 'health_insurance',
	first_name: 'John',
	last_name: 'Doe',
	street_1: '4321 Main Street',
	street_2: null,
	city: 'Thousand Oaks',
	state: 'CA',
	zip: '91360',
	county: 'Ventura',
	email: 'nextgentest321@gmail.com',
	phone: '4354262740',
	data: {
		dob: '1975-01-05',
		gender: 'male',
		height: 73,
		income: 38999,
		weight: 180,
		spanish: false,
		military: false,
		pregnant: false,
		plan_type: '',
		timeframe: 'In The Next Week',
		life_event: '',
		tobacco_user: false,
		interested_in: [],
		on_disability: false,
		plan_designer: [],
		household_size: 2,
		has_prescription: true,
		under_65_medicare: true,
		medical_conditions: [],
		insurance_timeframe: 'In the next week',
		medicare_enrollment: false,
		has_medical_condition: false,
		qualifying_life_event: 'None',
		major_medical_conditions: false,
	},
	dnc: false,
	leadid_token: '',
	trustedform_id: null,
	trustedform_cert_url: '',
	trustedform_share_url: null,
	elid: null,
	spoken_language: 'english',
	source_id: 'NG_HE_TEST',
	business_unit: 'OO',
	source_type: 'web',
	cost: 0,
	created_at: new Date(),
};

const product_options = [
	{
		label: 'Data Leads',
		value: 'data',
	},
	{
		label: 'Live Transfers',
		value: 'live_transfer',
	},
	{
		label: 'Calls',
		value: 'call',
	},
	{
		label: 'Ads',
		value: 'ad',
	},
];

const bid_type_options = [
	{
		label: 'Shared',
		value: 'shared',
	},
	{
		label: 'Exclusive',
		value: 'exclusive',
	},
];

const default_promotion = {
	name: '',
	icon_url: null,
	mpid: null,
	description: '',
	restrictions: '',
	custom_filter: [
		[
			{
				target: {
					path: '',
					transformer: null,
				},
				strategy: 'equals',
				comparator: {
					value: '',
					path: null,
					transformer: null,
					regex_flags: null,
				},
				match_missing: false,
				invert: false,
			},
		],
	],
	vertical_id: '',
	product: '',
	bid_types: ['exclusive'],
	start_date: null,
	end_date: null,
	allow_source_filtering: false,
	allow_attribute_filtering: false,
	allow_schedule: true,
	exclusive_minimum_bid: 0,
	shared_minimum_bid: 0,
	status: 'active',
};

export default {
	name: 'PromotionForm',
	setup() {
		return {
			sessionStore: useSessionStore(),
			v$: useVuelidate(),
		};
	},
	components: {
		imageUpload,
		pProgressSpinner,
		pTextArea,
		datePicker,
	},
	async mounted() {
		if (this.$route.meta.new && this.$route.query.duplicate) {
			const result = await getPromotionById(this.$route.query.duplicate);
			let duplicate = cloneDeep(result);
			delete duplicate.id;
			delete duplicate.created_at;
			duplicate.name += ' (Copy)';
			this.promotion = duplicate;
		}
		if (!this.$route.meta.new || this.$route.query.id) {
			this.promotion = await getPromotionById(this.$route.params.promotion_id);

			// NOT USED AT THE MOMENT
			// if (this.promotion.schedule && this.promotion.schedule.enabled) {
			// 	this.add_custom_schedule = true;
			// }
			if (Array.isArray(this.promotion.custom_filter) && this.promotion.custom_filter.length > 0) {
				this.add_custom_filter = true;
			}
		} else {
			this.promotion.mpid = this.$route.params.mpid || null;
		}
	},
	data() {
		return {
			vertical_options,
			product_options,
			bid_type_options,
			active_status_options,
			loading: false,
			promotion: cloneDeep(default_promotion),
			timezone: get(this.sessionStore, 'account.settings.timezone', 'America/Los_Angeles'),
			timezone_options: TIMEZONES,
			times_options: TIMES,
			days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
			show_test_modal: false,
			show_guide_modal: false,
			test_loading: false,
			test_date: new Date(),
			test_lead: test_lead,
			test_results: null,
			weekend_filter: `[ { "target": { "path": "date.day_of_week" }, "strategy": "one_of", "comparator": { "value": [ 1, 7 ] } } ]`,
			late_night_filter: `[ { "target": { "path": "date.hour" }, "strategy": "one_of", "comparator": { "value": [ 0, 1, 2, 3, 4, 5, 18, 19, 20, 21, 22, 23 ] } } ]`,
			both_filter: `[ { "target": { "path": "date.day_of_week" }, "strategy": "equals", "comparator": { "value": 5 } }, { "target": { "path": "date.hour" }, "strategy": "greater_than_or_equals", "comparator": { "value": 12 } } ], [ { "target": { "path": "date.day_of_week" }, "strategy": "equals_some", "comparator": { "value": [ 1, 6, 7 ] } } ]`,
		};
	},
	methods: {
		async testFilter() {
			this.test_loading = true;
			this.test_results = null;
			try {
				const result = await testPromotion({
					lead: this.test_lead,
					filter: this.promotion.custom_filter,
					timezone: this.timezone,
					date: this.test_date,
				});
				this.test_results = result;
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Error testing filter',
					life: 6000,
				});
			} finally {
				this.test_loading = false;
			}
		},
		async handleTestModal() {
			this.show_test_modal = true;
		},
		async handleSave() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				if (this.$route.meta.new) {
					// Insert
					try {
						const result = await insertPromotion(this.promotion);
						if (result) {
							this.$toast.add({
								summary: 'Created Promotion',
								life: 5000,
							});
							this.$router.back();
						}
					} catch (err) {
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to create Promotion',
							life: 6000,
						});
					}
				} else {
					// Update
					try {
						const result = await updatePromotion(this.$route.params.promotion_id, this.promotion);
						if (result) {
							this.$toast.add({
								summary: 'Updated Promotion',
								life: 5000,
							});
							this.$router.back();
						}
					} catch (err) {
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to update Promotion',
							life: 6000,
						});
					}
				}
			}
		},
		handleCustomFiltering(val) {
			if (val === true) {
				// Reset
				this.promotion.custom_filter = null;
			} else {
				// Set a default schedule
				this.promotion.custom_filter = [
					{
						target: {
							path: '',
							transformer: null,
						},
						strategy: 'equals',
						comparator: {
							value: '',
							path: null,
							transformer: null,
							regex_flags: null,
						},
						match_missing: false,
						invert: false,
					},
				];
			}
		},
		handleSchedule(val) {
			if (val === true) {
				// Empty schedule
				this.promotion.schedule = {
					enabled: false,
					timezone: '',
					periods: [],
				};
			} else {
				// Set a default schedule
				this.promotion.schedule = {
					enabled: true,
					timezone: 'America/Los_Angeles',
					periods: [
						{
							days: [1, 2, 3, 4, 5],
							start_time: 800,
							end_time: 1700,
						},
					],
				};
			}
		},
		handleDay(day) {
			if (this.promotion.schedule[0].days.includes(day)) {
				this.promotion.schedule[0].days = this.promotion.schedule[0].days.filter((item) => item !== day);
			} else {
				this.promotion.schedule[0].days.push(day);
			}
		},
		selectWeekdays() {
			this.promotion.schedule[0].days = [1, 2, 3, 4, 5];
		},
		selectWeekend() {
			this.promotion.schedule[0].days = [6, 7];
		},
	},
	computed: {
		availableEndTimes() {
			return this.times_options.filter((time) => time.value > this.promotion.schedule.periods[0].start_time);
		},
		timezoneOptions() {
			const is_dst = new Date().isDst();

			const timezones = TIMEZONES.map((tz) => {
				const offset = is_dst ? tz.offset_dst : tz.offset;
				return {
					label: `${tz.label} (${offset})`,
					value: tz.id,
					offset,
				};
			});

			return sortBy(timezones, (tz) => {
				return parseInt(tz.offset);
			});
		},
	},
	validations() {
		return {
			promotion: {
				name: {
					required: helpers.withMessage('A name is required.', required),
				},
				description: {
					required: helpers.withMessage('A description is required.', required),
				},
				vertical_id: {
					required: helpers.withMessage('A vertical is required.', required),
				},
				product: {
					required: helpers.withMessage('A product is required.', required),
				},
				bid_types: {
					required: helpers.withMessage('At least one bid type is required.', required),
					minLength: minLength(1),
				},
				exclusive_minimum_bid: {
					required: helpers.withMessage('A bid value is required.', required),
				},
				shared_minimum_bid: {
					required: helpers.withMessage('A bid value is required.', required),
				},
				status: {
					required: helpers.withMessage('Status is required.', required),
				},
			},
		};
	},
};
</script>

<style scoped lang="less">
@import (reference) '@/styles/responsive';
.days-options {
	.days-label {
		.mobile({
			flex-basis: 100%;
		});
	}
}

.quick-select {
	font-size: var(--font-size-sm);
	margin: 1rem 0;

	a {
		cursor: pointer;
	}
}

.time-options {
	font-size: var(--font-size-sm);
	padding: 1rem 0;

	.timezone .name {
		font-weight: bold;
		padding: 0.75rem 0;
	}
}

.days {
	display: flex;
	flex: 1 0 auto;
	flex-flow: row wrap;

	.active-day {
		background: var(--color-b);
		border-radius: 16px;
		color: white;
		display: inline-block;
		padding: 0.5rem 0.75rem;
	}
}
</style>
