import { $GQL } from '@GQL/index';
import { type Marketplace } from '@nextgenleads/marketplace-driver';

export async function getMarketplaceById(id: string): Promise<Marketplace> {
	const query = `
        query GetMarketplace($id: ID!) {
            marketplace(id: $id) {
                id
                name
                owner_id
                status
				settings {
					logo_url
					favicon_url
					login_bkg_url
					theme
					default_parent_account_id
				}
				contact {
					name
					title
					email
					phone
				}
                created_at
                modified_at
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.marketplace;
}
