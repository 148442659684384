<template>
	<!--	<icon-->
	<!--		v-tooltip.top="'Upload Verification Documents'"-->
	<!--		class="action"-->
	<!--		type="license"-->
	<!--		size="24px"-->
	<!--		@click.prevent="show_verification_modal = !show_verification_modal"-->
	<!--	/>-->
	<p-dialog
		v-model:visible="show_verification_modal"
		class="verify-payment-method-modal"
		:modal="true"
		:dismissable-mask="true"
		:pt="{
			root: { class: 'w-12 sm:w-12 md:w-6 max-w-30rem' },
		}"
	>
		<loader :show="loading" />
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="license" size="24px" style="color: var(--color-b)" />
				<strong>Upload Verification Documents</strong>
			</div>
		</template>
		<div v-if="pendingVerification && !more_uploads">
			<p-message severity="warn" style="margin-top: 0" :closable="false">
				<div class="text-center">
					<strong>Verification for this payment method is pending</strong><br />
					You will be notified once verification is complete
				</div>
			</p-message>

			<div class="flex column"></div>
		</div>
		<div v-else>
			<div>
				<p>Verifying your payment method helps us fight fraud and will increase your deposit limits for this card.</p>
				<p>
					To verify your payment method, please upload a photo of the front of a driver's license or ID (with a name
					that matches the credit card) placed on top of the credit card and cover all but the last 4 digits of the card
					number.
				</p>
				<p><strong>See Example Below</strong></p>
				<p class="text-center mt-4 mb-4">
					<img class="max-w-20rem" src="/assets/img/illustrations/id.svg" alt="Drivers License Illustration" />
				</p>
				<div class="file-upload">
					<image-upload
						v-model="url"
						:folder="folderName"
						mode="advanced"
						@success="handleUpload"
						:multiple_files="true"
					/>
				</div>
			</div>
			<div v-if="!valid">
				<p class="validation-error" style="max-width: 400px">
					<strong>More than one file for verification is required</strong>, at least one of the card and one of your
					ID/DL. You can select or drag multiple files here.
				</p>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button
					text
					v-if="pendingVerification && !more_uploads"
					label="Upload More Docs"
					@click="
						more_uploads = true;
						url = '';
					"
				/>
				<p-button
					icon="pi pi-check"
					:label="!this.uploaded ? 'Send Notice' : 'Submit For Verification'"
					@click="submitForVerification"
				/>
			</div>
		</template>
	</p-dialog>
</template>

<script lang="ts">
import pButton from 'primevue/button';
import pDialog from 'primevue/dialog';
import { getFiles, sendEmail } from '@GQL';
import pMessage from 'primevue/message';
import ImageUpload from '@/components/forms/ImageUpload.vue';

export default {
	name: 'VerificationUploadModal',
	components: {
		pButton,
		pDialog,
		ImageUpload,
		pMessage,
	},
	emits: ['refresh'],
	computed: {
		accountId() {
			return (
				this.$route.params.account_id || this.$route.params.parent_account_id || this.$root.sessionStore.account.id
			);
		},
		pendingVerification() {
			return this.files.length > 0;
		},
		folderName() {
			return `payment-methods/${this.payment_method_id}`;
		},
		folderPrefix() {
			let folder_prefix = '';
			if (!this.$root.sessionStore.isAdminUser) {
				folder_prefix += this.$root.appStore.mpid + '/';
			} else if (this.$route.params.mpid) {
				folder_prefix += this.$route.params.mpid + '/';
			}

			folder_prefix += this.accountId + '/';

			return folder_prefix;
		},
	},
	data() {
		return {
			payment_method_id: '',
			name_on_card: '',
			last_four: '',
			valid: false,
			loading: false,
			uploaded: false,
			more_uploads: false,
			show_verification_modal: false,
			files: [],
			url: '',
		};
	},
	methods: {
		async openModal(card) {
			// reset
			this.valid = false;
			this.uploaded = false;
			this.more_uploads = false;
			this.files = [];
			this.url = '';
			// data should have the card information
			this.payment_method_id = card.id;
			this.name_on_card = card.name_on_card;
			this.last_four = card.last_four;

			await this.getVerificationFiles();

			this.show_verification_modal = true;
		},
		handleUpload(upload_data) {
			// here we set the URL, show the image, then we can proceed with the verification
			this.files = upload_data;
			this.uploaded = true;
			if (this.more_uploads) {
				// this means this is the second or third time uploading
				this.more_uploads = false;
			}
		},
		async getVerificationFiles() {
			const full_path = (this.folderPrefix + this.folderName).replace(/\/\//, '/');
			const results = await getFiles(full_path);
			this.folders = results.folders;
			this.files = results.files;

			if (this.files.length > 0) {
				this.valid = true;
			}
		},
		async submitForVerification() {
			if (this.pendingVerification) {
				this.loading = true;
				try {
					const email_addresses = [
						{
							name: 'Support',
							email: 'support@nextgenleads.com',
						},
					];
					if (
						'email' in this.$root.sessionStore.account.support.account_manager &&
						this.$root.sessionStore.account.support.account_manager.email
					) {
						email_addresses.push({
							name:
								this.$root.sessionStore.account.support.account_manager.first_name +
								' ' +
								this.$root.sessionStore.account.support.account_manager.last_name,
							email: this.$root.sessionStore.account.support.account_manager.email,
						});
					}

					const result = await sendEmail({
						recipients: email_addresses,
						template: {
							id: '01J6Z0BDMQQ7WM4PQ6T0KYSKST',
							data: {
								mpid: this.accountId.slice(0, 3),
								account_id: this.accountId,
								name_on_card: this.name_on_card,
								last_four: this.last_four,
							},
						},
						sender_user_id: this.$root.sessionStore.user.id,
						account_id: this.accountId,
					});

					// send an email to the account manager who's is in the user
					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: 'Your account manager has been notified.',
							detail: 'They will attempt to verify your documents and get back to you.',
							life: 5000,
						});
						this.show_verification_modal = false;
						this.$emit('refresh');
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to verify, files may be missing',
						life: 6000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to verify, files may be missing',
					life: 6000,
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
.file-upload {
	margin: 1rem auto;
	text-align: center;
}

.verify-content {
	margin: 0 auto;
	max-width: 400px;
	text-align: left;

	img {
		max-width: 350px;
	}
}

.action {
	//color: var(--gray-50);
	color: red;
	cursor: pointer;

	&:hover {
		color: var(--color-b);
	}
}
</style>
