<template>
	<div class="edit-campaign">
		<h2>Final Step: Configure Your Campaign {{ isTemplate ? 'Template' : '' }}</h2>
		<p>Set up your campaign's targeting and delivery below. If you need help, contact your Account Manager.</p>
		<div class="edit-campaign-dashboard">
			<CampaignToolbar v-if="mq.tabletPlus" :active-sections="sections_active" @open-section="handleSection" />
			<div class="sections">
				<p-accordion v-model:activeIndex="sections_active" multiple>
					<p-accordion-tab>
						<template #header>
							<icon type="hand-coin-outline" size="24px" class="campaign-icon" />
							<span>Bid and Budgets</span>
						</template>
						<BidAndBudgetSection />
					</p-accordion-tab>
					<p-accordion-tab>
						<template #header>
							<icon type="map-marker-circle" size="24px" class="campaign-icon" />
							<span>Location Targeting</span>
						</template>
						<LocationSectionBasic v-if="campaign.mode === 'basic'" />
						<LocationSection v-else />
					</p-accordion-tab>
					<p-accordion-tab>
						<template #header>
							<icon type="bullseye-arrow" size="24px" class="campaign-icon" />
							<span>Lead Attribute Filtering</span>
						</template>
						<template v-if="campaign.promotion_id && !campaignStore.promotion.allow_attribute_filtering">
							<p-message severity="info" :closable="false">
								The promotion attached to this campaign does not allow attribute filtering.
							</p-message>
						</template>
						<template v-else>
							<FilteringSectionBasic v-if="campaign.mode === 'basic'" />
							<FilteringSection v-else />
						</template>
					</p-accordion-tab>
					<p-accordion-tab>
						<template #header>
							<icon type="calendar-clock" size="24px" class="campaign-icon" />
							<span>Schedule</span>
						</template>
						<template v-if="campaign.promotion_id && !campaignStore.promotion.allow_schedule">
							<p-message severity="info" :closable="false">
								The promotion attached to this campaign does not allow a custom campaign schedule.
							</p-message>
						</template>
						<template v-else>
							<ScheduleSection />
						</template>
					</p-accordion-tab>
					<p-accordion-tab>
						<template #header>
							<icon type="speedometer-slow" size="24px" class="campaign-icon" />
							<span>Purchase Throttling</span>
						</template>
						<ThrottlingSection />
					</p-accordion-tab>
					<p-accordion-tab
						v-if="
							campaignStore.campaign.product_targeting === 'data' &&
							campaignStore.campaign.bid_type === 'exclusive' &&
							!isTemplate
						"
					>
						<template #header>
							<icon type="medal-outline" size="24px" class="campaign-icon" />
							<span>Premium Listings</span>
						</template>
						<PremiumListingSection />
					</p-accordion-tab>
					<p-accordion-tab v-if="!isTemplate">
						<template #header>
							<icon type="truck" size="24px" class="campaign-icon" />
							<span>Lead Delivery</span>
						</template>
						<DeliverySection />
					</p-accordion-tab>
					<p-accordion-tab v-if="sessionStore.isAdminUser" headerClass="admin-only" contentClass="admin-only">
						<template #header>
							<div class="flex-header">
								<div class="left">
									<icon type="code-block-tags" size="24px" class="campaign-icon" />
									<span>Custom Filtering/Bidding (Admin Only)</span>
								</div>
								<div class="right">
									<icon type="eye-lock-outline" size="24px" />
								</div>
							</div>
						</template>
						<CustomSection :product="campaign.product_targeting" :vertical_id="campaign.vertical_id_targeting" />
					</p-accordion-tab>
					<p-accordion-tab
						v-if="sessionStore.isAdminUser && !isTemplate"
						headerClass="admin-only"
						contentClass="admin-only"
					>
						<template #header>
							<div class="flex-header">
								<div class="left">
									<icon type="lan-connect" size="24px" class="campaign-icon" />
									<span>Ping/Post Settings (Admin Only)</span>
								</div>
								<div class="right">
									<icon type="eye-lock-outline" size="24px" />
								</div>
							</div>
						</template>
						<PingPostSection />
					</p-accordion-tab>
					<p-accordion-tab
						v-if="sessionStore.isAdminUser && sessionStore.hasPermission('Campaign.campaign_flags')"
						headerClass="admin-only"
						contentClass="admin-only"
					>
						<template #header>
							<div class="flex-header">
								<div class="left">
									<icon type="order-bool-ascending-variant" size="24px" class="campaign-icon" />
									<span>Campaign Flags (Admin Only)</span>
								</div>
								<div class="right">
									<icon type="eye-lock-outline" size="24px" />
								</div>
							</div>
						</template>
						<CampaignFlagsSection />
					</p-accordion-tab>
					<p-accordion-tab
						v-if="sessionStore.isAdminUser && sessionStore.hasPermission('Campaign.campaign_flags')"
						headerClass="admin-only"
						contentClass="admin-only"
					>
						<template #header>
							<div class="flex-header">
								<div class="left">
									<icon type="history" size="24px" class="campaign-icon" />
									<span>Campaign Version History (Admin Only)</span>
								</div>
								<div class="right">
									<icon type="eye-lock-outline" size="24px" />
								</div>
							</div>
						</template>
						<p-card>
							<template #content>
								<strong>Campaign Version History:</strong>
								<gutter size="20px" />
								<CampaignVersionsSection />
							</template>
						</p-card>
					</p-accordion-tab>
				</p-accordion>
			</div>
			<div class="details">
				<div class="campaign-summary">
					<div class="header">
						<icon type="card-text" size="24px" class="campaign-icon" style="color: var(--color-b)" />
						<span>Campaign Summary</span>
					</div>
					<div class="content">
						<div class="flex flex-column gap-2">
							<label for="campaign_name">Campaign Name:</label>
							<p-input-text
								id="campaign-name"
								v-model="campaign.name"
								class="p-inputtext"
								placeholder="Your Campaign Name"
								autofocus
								:disabled="
									get(campaignStore.campaign, 'flags.frozen_fields.enabled', false) &&
									get(campaignStore.campaign, 'flags.frozen_fields.fields', []).includes('name')
								"
								@blur="v$.campaign.name.$touch()"
							/>
							<div v-if="v$.campaign.name.$error">
								<div v-for="error in v$.campaign.name.$errors" class="validation-error">
									{{ error.$message }}
								</div>
							</div>
						</div>
						<gutter size="15px" />
						<div class="flex flex-column gap-2">
							<label for="campaign_description">Description:</label>
							<p-input-text
								id="description"
								v-model="campaign.description"
								class="p-inputtext"
								placeholder="Your Campaign Description"
								@blur="v$.campaign.description.$touch()"
							/>
							<div v-if="v$.campaign.name.$error">
								<div v-for="error in v$.campaign.name.$errors" class="validation-error">
									{{ error.$message }}
								</div>
							</div>
						</div>
						<template v-if="sessionStore.isAdminUser">
							<gutter size="15px" />
							<div class="flex flex-column gap-2">
								<label for="campaign_description">Display Name:</label>
								<p-input-text
									id="display-name"
									v-model="campaign.display_name"
									class="p-inputtext"
									placeholder="Custom display name override"
								/>
							</div>
						</template>
						<div class="bid-range">
							<div class="label">Bid Range</div>
							<template v-if="campaignStore.bidRange[0] === campaignStore.bidRange[1]">
								<div class="value">
									{{ currency(campaignStore.bidRange[0]) }}
								</div>
								<p class="description">
									This campaign will bid <strong>{{ currency(campaignStore.bidRange[0]) }}</strong> on all leads.
								</p>
							</template>
							<template v-else>
								<div class="value">
									{{ currency(campaignStore.bidRange[0]) }} - {{ currency(campaignStore.bidRange[1]) }}
								</div>
								<p class="description">
									This campaign will bid between <strong>{{ currency(campaignStore.bidRange[0]) }}</strong> and
									<strong>{{ currency(campaignStore.bidRange[1]) }}</strong> on all leads and will never pay a price
									outside of this range.
								</p>
							</template>
						</div>
						<div class="campaign-settings">
							<ul class="table-list">
								<!-- <li>
									<div class="label">
										<icon type="briefcase" size="14px" class="label-icon" />
										Vendor
									</div>
									<div class="value">{{ vendorName(campaign.vendor_id_targeting) }}</div>
								</li> -->
								<li>
									<div class="label">
										<icon type="plus-box" size="14px" class="label-icon" />
										Vertical
									</div>
									<div class="value">{{ capitalize(campaign.vertical_id_targeting) }}</div>
								</li>
								<li>
									<div class="label">
										<icon type="account-circle" size="14px" class="label-icon" />
										Product
									</div>
									<div class="value">{{ capitalize(campaign.product_targeting) }}</div>
								</li>
								<li>
									<div class="label">
										<icon type="store-cog" size="14px" class="label-icon" />
										Bid Type
									</div>
									<div class="value">{{ capitalize(campaign.bid_type) }}</div>
								</li>
								<li v-if="campaign.promotion_id">
									<div class="label">
										<icon type="creation" size="14px" class="label-icon" />
										Promotion
									</div>
									<div class="value">{{ capitalize(campaignStore.promotion.name) }}</div>
								</li>
								<!-- <li>
									<div class="label">
										<icon type="format-list-bulleted-square" size="14px" class="label-icon" />
										Channel
									</div>
									<div class="value">{{ capitalize(channel.name) }}</div>
								</li> -->
								<li>
									<div class="label">
										<icon type="power" size="14px" class="label-icon" />
										Status
									</div>
									<div class="value">{{ capitalize(campaign.status) }}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div v-if="campaign.mode === 'advanced'" class="exact-bidding-info">
					<div class="card padded">
						<div class="flex align-items-center">
							<icon type="help-circle" size="24px" />
							<h3 class="info">How Exact Bidding Works</h3>
						</div>
						<gutter size="10px" />
						<p>If a lead matches multiple exact bids, the first bid it matches will be used.</p>
						<p>Bids are matched in the following order:</p>
						<ul class="bulleted">
							<li>Location</li>
							<li>Attributes</li>
							<li>Schedule</li>
						</ul>
					</div>
				</div>
				<div class="card">
					<div v-if="$route.name.indexOf('CreateCampaign') === -1" class="links">
						<div class="link-item">
							<router-link
								:to="$route.path + ($root.sessionStore.isAdminUser ? '/reports' : '/stats')"
								class="icon"
								v-tooltip.top="'Campaign Reports'"
							>
								<icon type="chart-box-outline" size="24px" />
								<div class="label">View Campaign Reports</div>
							</router-link>
						</div>
						<div class="link-item">
							<router-link :to="`${$route.path}/purchase-report`" class="icon" v-tooltip.top="'Purchase History'">
								<icon type="receipt-clock" size="24px" />
								<div class="label">View Purchases</div>
							</router-link>
						</div>
					</div>
				</div>
				<!-- <div class="volume-estimation">
					<div class="header">
						<icon type="gauge" size="24px" class="campaign-icon" style="color: var(--color-b)" />
						<span>Lead Volume Estimation</span>
					</div>
					<div class="content">
						<div class="estimation">
							<div class="label">Location Volume</div>
							<div class="value">~2,500 leads/day</div>
						</div>
						<div class="estimation">
							<div class="label">Lead Attribute Filtering</div>
							<div class="value positive">80%</div>
						</div>
						<div class="estimation">
							<div class="label">Campaign Schedule</div>
							<div class="value neutral">70%</div>
						</div>
						<div class="estimation">
							<div class="label">Total Estimated Volume</div>
							<div class="value neutral">~1,400 leads/day</div>
						</div>
					</div>
				</div> -->
			</div>
		</div>
		<p-dialog id="bid-info-modal" v-model:visible="campaignStore.show_bid_info_modal" modal style="max-width: 760px">
			<template #header>
				<strong>How Does Advanced Bidding Work?</strong>
			</template>
			<p>
				In advanced mode, you can adjust bids on a campaign based on location of the lead (state or zip), lead
				attributes (age, income, etc.), and time of day or week. The price you pay will always be within the Bid Range
				showing in the right column. This range is determined by the following factors:
			</p>
			<ul class="bulleted">
				<li>
					<strong>Default Bid:</strong> Bid calculations start with this amount and are adjusted up or down. For leads
					that do not match any modifiers, your Default Bid will be used in the auction.
				</li>
				<li>
					<strong>Maximum Bid:</strong> If you enable the maximum bid, this is the most you will ever pay for a lead
					even if an adjustment would push the bid above this number. This amount should be the most you are willing to
					pay for any lead.
				</li>
				<li>
					<strong>Minimum Bid:</strong> This number cannot be adjusted and is the minimum amount you may set your bid
					to.
				</li>
			</ul>
			<h3>Second Pricing</h3>
			<p>
				For auctions that allow second pricing, your calculated bid will be adjusted down to 1¢ above the next highest
				bidder for a lead. That means if you bid $20 for a lead, but the next highest bidder is bidding $15, you will
				pay $15.01 for the lead. If there are no other bidders in an auction, your bid will be priced down to the
				<strong>minimum bid</strong>.
			</p>
			<h3>Making Bid Adjustments</h3>
			<p>
				Location, attribute, and schedule groups allow you to adjust your bid in 4 different ways if the criteria is
				matched on a lead. Adjustments are made in a specific order (location, attribute, then schedule) and use the
				highest matching adjustment to ensure you win the best quality leads. The options for adjusting your bid are as
				follows:
			</p>
			<ul class="bulleted">
				<li><strong>Do Not Adjust Bid:</strong> Do not modify the bid based on this attribute.</li>
				<li><strong>Apply Exact Bid:</strong> Set an exact bid on matching leads, ignoring other modifiers.</li>
				<li>
					<strong>Add To / Subtract From Bid:</strong> Add a flat dollar amount to your bid. A negative amount can be
					entered to reduce the bid.
				</li>
				<li>
					<strong>Multiply the Bid:</strong> Multiply the current calculated bid by the percentage set. If you do not
					want to adjust your bid, select this option and enter 100%.
				</li>
				<li><strong>Do Not Bid:</strong> Filter out leads matching these attributes.</li>
			</ul>
			<template v-if="$root.sessionStore.isAdminUser">
				<h3>Finalizing Bids (Locked Bid)</h3>
				<p>
					You can the lock icon to the right of the bid adjustment to <strong>finalize the bid</strong>. This will stop
					making additional adjustments to the bid if the rule is matched by a lead.
				</p>
			</template>
		</p-dialog>
	</div>
</template>

<script lang="ts">
import CampaignToolbar from '../Components/SectionToolbar.vue';
import pAccordion from 'primevue/accordion';
import pAccordionTab from 'primevue/accordiontab';
import BidAndBudgetSection from '../Sections/CampaignBidAndBudgets.vue';
import LocationSection from '../Sections/LocationTargeting.vue';
import LocationSectionBasic from '../Sections/LocationTargetingBasic.vue';
import FilteringSection from '../Sections/AttributeFiltering.vue';
import FilteringSectionBasic from '../Sections/AttributeFilteringBasic.vue';
import ScheduleSection from '../Sections/CampaignSchedule.vue';
import CustomSection from '../Sections/CustomFiltering.vue';
import PremiumListingSection from '../Sections/PremiumListings.vue';
import ThrottlingSection from '../Sections/Throttling.vue';
import PingPostSection from '../Sections/PingPost.vue';
import DeliverySection from '../Sections/LeadDelivery.vue';
import CampaignFlagsSection from '../Sections/CampaignFlags.vue';
import CampaignVersionsSection from '../Sections/Versions.vue';
import pDialog from 'primevue/dialog';
import { capitalize, currency } from '@/lib/Filters';
import { useCampaignStore } from '@/stores/campaign';
import { useSessionStore } from '@/stores/session';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import { mapState, mapWritableState, mapStores } from 'pinia';
import { get } from 'lodash-es';
import FailedTransferModal from '@/views/LTWorkstation/Results/FailedTransferModal.vue';

export default {
	name: 'EditCampaign',
	components: {
		CampaignToolbar,
		pAccordion,
		pAccordionTab,
		BidAndBudgetSection,
		LocationSection,
		LocationSectionBasic,
		FilteringSection,
		FilteringSectionBasic,
		ScheduleSection,
		CustomSection,
		PremiumListingSection,
		ThrottlingSection,
		PingPostSection,
		DeliverySection,
		CampaignFlagsSection,
		CampaignVersionsSection,
		pDialog,
	},
	inject: ['mq'],
	emits: ['prev', 'next'],
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			sections_active: [0],
		};
	},
	computed: {
		campaignErrors() {
			const validations = [
				'defaultBid',
				'customBid.amount',
				'maximumBid',
				'campaign.name',
				'noActiveSchedules',
				'purchase_throttling_limit',
				'purchase_throttling_interval',
				'selectedPL',
				'campaign.forwarding_phone.number',
			];

			// Add if not a template
			if (!this.isTemplate) {
				validations.push('locationTargeting');
			}

			return this.v$.$errors.filter((error) => {
				return validations.includes(error.$propertyPath);
			});
		},
		...mapWritableState(useCampaignStore, ['campaign', 'channel']),
		...mapState(useSessionStore, ['role']),
		...mapStores(useCampaignStore, useSessionStore),
		isTemplate() {
			return this.$route.meta.template_mode;
		},
	},
	validations() {
		return {
			campaign: {
				name: {
					required: helpers.withMessage('A campaign name is required', required),
				},
				forwarding_phone: {
					number: {
						required: helpers.withMessage(
							'Phone number is required in the delivery portion for Call or Live transfer',
							requiredIf(['call', 'live_transfer'].indexOf(this.campaign.product_targeting) > -1 && !this.isTemplate)
						),
					},
				},
			},
		};
	},
	methods: {
		capitalize,
		currency,
		get,
		handleSection(section_index) {
			if (Array.isArray(section_index)) {
				this.sections_active = section_index;
			} else {
				this.sections_active = [section_index];
			}
		},
		formatStringArr(input) {
			if (input.length > 0) {
				let capitalized = input.map((item) => {
					return capitalize(item);
				});
				return capitalized.join(', ');
			} else {
				return 'None';
			}
		},
		vendorName(vendor_id) {
			const vendor = this.$root.appStore.options.vendors.find((vendor) => {
				return vendor.value === vendor_id;
			});

			if (!vendor) {
				return 'Unknown';
			}
			return vendor.label;
		},
	},
	mounted() {
		if (this.$route.meta.new) {
			// Set the default and minimum bid
			this.campaignStore.campaign.default_bid = this.campaignStore.minimumBid;
			this.campaignStore.campaign.minimum_bid = this.campaignStore.minimumBid;
		}
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

h2 {
	font-size: var(--font-size-h4);
}

.flex-header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 100%;

	.left {
		align-items: center;
		display: flex;
		flex: 1 0;
	}

	.right {
		flex: 0 0 auto;
	}
}

.details {
	position: sticky;
	top: 0;
	max-width: 400px;
}

.edit-campaign-dashboard {
	align-items: flex-start;
	display: flex;
	flex: 1 0;
	flex-flow: row wrap;
	gap: 1rem;
	justify-content: flex-start;

	.sections {
		flex: 1 0;
		max-width: 1000px;
		width: 100%;

		.laptop-up({
			min-width: 300px;
		});
	}

	.tablet-down({
		flex-direction: column-reverse;

		.details {
			flex: 1 0;
			position: static;
			width: 100%;
		}
	});
}

.campaign-icon {
	margin: 0 0.5rem;
}

.campaign-summary,
.volume-estimation {
	background: var(--white);
	border: 1px solid #dee2e6;
	border-radius: 4px;
	margin-bottom: 1rem;

	.header {
		align-items: center;
		border-bottom: 1px solid var(--gray-15);
		display: flex;
		flex-direction: row;
		font-weight: bold;
		justify-content: flex-start;
		padding: 1rem 1.25rem;

		img {
			margin-right: 0.5rem;
		}
	}

	.content {
		padding: 1rem 1.25rem;

		label {
			color: var(--gray-60);
			text-transform: uppercase;
		}

		.campaign-settings {
			margin-top: 1rem;

			.table-list li {
				justify-content: space-between;

				.label {
					align-items: center;
					display: flex;
					gap: 0.5rem;
				}
			}

			.label {
				color: var(--gray-60);
				flex: unset;
				font-weight: normal;
			}

			.label-icon {
				color: var(--color-b);
			}

			.value {
				font-weight: bold;
			}

			img {
				height: 13px;
				margin-right: 5px;
				width: 13px;
			}
		}

		.estimation {
			border: 1px solid var(--gray-10);
			border-radius: 4px;
			display: flex;
			flex-direction: row;
			font-size: var(--font-size-sm);
			gap: 1rem;
			justify-content: space-between;
			margin-bottom: 1rem;
			padding: 8px;

			.label,
			.value {
				color: var(--gray-60);

				&.positive {
					color: var(--green);
				}

				&.neutral {
					color: var(--yellow);
				}

				&.negative {
					color: var(--red);
				}
			}
		}

		.laptop-up({
			min-width: 320px;
			max-width: 350px;
		});
	}
}

.bid-range {
	border: 1px solid var(--gray-20);
	border-radius: 5px;
	margin-top: 1rem;
	padding: 0.75rem;

	.label {
		color: var(--gray-65);
		font-size: 0.875rem;
		font-weight: bold;
		text-transform: uppercase;
	}

	.value {
		font-size: 1.5rem;
		font-weight: bold;
		margin-top: 1rem;
	}
	.description {
		color: var(--gray-45);
		line-height: 1.35em;
		margin-top: 0.5em;
	}
}

.exact-bidding-info {
}

:deep(.custom-bids) {
	margin: 1em 0 0;
}

:deep(.p-accordion-tab.p-accordion-tab-active > .p-accordion-header > a) {
	& > .campaign-icon {
		color: var(--color-b);
	}

	background: var(--color-b-lightest) !important;
}

:deep(.p-accordion-header.admin-only a),
:deep(.p-accordion-header.p-highlight.admin-only a) {
	background-color: #ffe48c !important;
	border-color: #ffcc26 !important;

	.right .mdi-icon {
		color: #bf9200;
	}
}
:deep(.p-accordion-header.admin-only a:focus) {
	box-shadow: inset 0 0 0 2px#ffcc26 !important;
}

:deep(.admin-only > .p-accordion-content) {
	background-color: #fffcf2;
	border-color: #ffe48c;
}

:deep(label.admin-only::after) {
	content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>eye-lock</title><path d="M20.8 17V15.5C20.8 14.1 19.4 13 18 13S15.2 14.1 15.2 15.5V17C14.6 17 14 17.6 14 18.2V21.7C14 22.4 14.6 23 15.2 23H20.7C21.4 23 22 22.4 22 21.8V18.3C22 17.6 21.4 17 20.8 17M19.5 17H16.5V15.5C16.5 14.7 17.2 14.2 18 14.2S19.5 14.7 19.5 15.5V17M15 12C14.1 12.7 13.5 13.6 13.3 14.7C12.9 14.9 12.5 15 12 15C10.3 15 9 13.7 9 12S10.3 9 12 9 15 10.3 15 12M12 19.5C7 19.5 2.7 16.4 1 12C2.7 7.6 7 4.5 12 4.5S21.3 7.6 23 12C22.8 12.5 22.5 13 22.3 13.5C21.5 12 19.8 11 18 11C17.6 11 17.3 11.1 16.9 11.1C16.5 8.8 14.5 7 12 7C9.2 7 7 9.2 7 12S9.2 17 12 17H12.3C12.1 17.4 12 17.8 12 18.2V19.5Z" /></svg>');
	display: inline-block;
	height: 18px;
	margin: -1px 0 0 5px;
	vertical-align: text-top;
	width: 18px;
}

h3.info {
	font-size: 1.125rem;
	font-weight: bold;
	margin: 0 0 0 0.5em;
}

.links {
	padding: 10px 20px;
}

.link-item {
	border-top: 1px solid var(--gray-15);

	a {
		align-items: center;
		color: var(--gray-70);
		display: flex;
		gap: 10px;
		height: 40px;
		line-height: 40px;

		.mdi-icon {
			margin-bottom: 4px;
		}

		.label {
			font-size: 0.875rem;
		}
	}

	.mdi-icon {
		color: var(--color-b);
	}

	&:first-child {
		border-top: 0;
	}
}
</style>

<style lang="less">
#bid-info-modal {
	h3 {
		font-size: 1.125rem;
		font-weight: bold;
		margin-top: 1.5em;
	}

	ul {
		margin-bottom: 1em;
	}
}
</style>
