import { $GQL } from '@GQL/index';
import type { Account } from '@nextgenleads/marketplace-driver';

export async function getAccountOverviewInfo(id: string, refresh?: boolean): Promise<Partial<Account>> {
	const query = `
        query getAccountOverviewInfo($id: ID) {
            account(id: $id) {
                id
                name
                support {
                    disposition	
                    account_manager_id
                    account_manager
                    {
                        id
                        first_name
                        last_name
                    }
                    date_of_return
                    status
                    kpi_targets {
                        data_cpa
                        call_cpa
                        live_transfer_cpa
                        products_sold
                    }
                }
                created_at
                balance {
                    actual
                }
                settings {
                    timezone
                }
                contact {
                    email
                }
                signup_data {
                    primary_vertical
                    phone_validation {
                        activity_score
                        carrier
                        line_type
                    }
                    products
                    agency_size
                    referred_from
                }
                signup_source {
                    source_id
                    s1
                    s2
                }
                lastActivity
                lastContact
                lastPurchase
                todaysSpend
                yesterdaysSpend
                primary_user {
                    first_name
                    last_name
                    email
                    phone
                }
                status
            }
        }
    `;

	const no_cache = refresh ? ['account'] : undefined;
	const result = await $GQL.request(query, { id, no_cache });
	return result.account;
}
